import React, { useState, useEffect } from "react";
import useClickOutside from "hooks/useClickOutside";
import { usePopper } from "react-popper";
import { Input } from "components/core/Forms/Input";
import { SearchIcon, XIcon } from "assets/icons";
import { List, ListItem, Badge } from "components/core";
import useDebounceEffect from "hooks/useDebounceEffect";
import {
  extractTextFromJSX,
  isNull,
  isNullEmptyOrWhitespace,
} from "helpers/stringUtilities";

export interface IOption {
  id: string;
  text: string;
  onClick?: () => void;
  meta?: {[key:string]: any};
}

interface IButtonOptionsProps {
  options: IOption[];
  title?: string;
  filters?: { [key: string]: any } | undefined;
  buttonElement?: Element;
  className?: string;
  style?: React.CSSProperties;
  setShow?: (show: boolean) => void;
  showStats?: boolean;
  showSearch?: boolean;
  onHideOptions?: () => void;
  optionProps?: any;
}

export default function ButtonOptions({
  options,
  title,
  filters,
  buttonElement,
  className,
  style,
  setShow,
  showStats,
  showSearch,
  onHideOptions,
  optionProps,
  ...other
}: IButtonOptionsProps) {
  const [popperElement, setPopperElement] = useState<any>(undefined);
  const [arrowElement, setArrowElement] = useState<any>(undefined);
  const [filteredOptions, setFilteredOptions] = useState<IOption[]|undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  // Expected format: { Property: ["option1", "option2"]}
  const [optionsFilters, setOptionsFilters] =
    useState<IButtonOptionsProps["filters"]>(filters);

  const { styles, attributes } = usePopper(buttonElement, popperElement, {
    // placement: "top-start",
    modifiers: [
      { name: "offset", options: { offset: [0, 10] } },
      { name: "arrow", options: { element: arrowElement } },
      // { name: "preventOverflow", options: { padding: 10 }} // Not working correctly
    ],
  });

  //#region Callbacks

  function handleClickOutside(ev: MouseEvent) {
    if (setShow) setShow(false);

    if (!!onHideOptions) onHideOptions();
  }

  const handleOptionOnClick = (ev: React.MouseEvent<HTMLElement>, option: IOption) => {
    ev.preventDefault();
    if (setShow) setShow(false);

    if (!!onHideOptions) onHideOptions();

    !!option.onClick && option.onClick();
  };

  const handleChangeSearchQuery = (value: string) => {
    setSearchQuery(value);
  };

  const removeFilter = (key: string, value: string) => {
    setOptionsFilters((prevState: IButtonOptionsProps["filters"]) => {
      const newState = prevState !== undefined ? { ...prevState } : {};
      newState[key] = newState[key].filter((v: string) => v !== value);

      // Delete key if no items left in array
      if (newState[key].length === 0) delete newState[key];

      return newState;
    });
  };

  //#endregion

  //#region Side-effects

  /**
   * Debounced, only trigger every X seconds
   * Listen for changes to options and reapply filter
   */
  useDebounceEffect(
    () => {
      let _filteredOptions = null;
      if (!isNull(options)) {
        const normalisedSearchQuery = searchQuery.toLowerCase();
        _filteredOptions = [...options].filter((opt) => {
          let result = true;

          // Search query
          if (typeof opt.text == "string") {
            result =
              opt.text.toLowerCase().includes(normalisedSearchQuery) || // Filter by text
              opt.id.toString().toLowerCase().includes(normalisedSearchQuery);
          } else if (typeof opt.text == "object") {
            let text = extractTextFromJSX(opt.text);
            result = text?.toLowerCase()?.includes(searchQuery.toLowerCase());
          }

          // Meta
          if (
            !isNullEmptyOrWhitespace(opt.meta) &&
            result === true &&
            optionsFilters !== undefined
          ) {
            for (const [key, value] of Object.entries(optionsFilters)) {
              result = someValueInMeta(value, opt, key) ?? result;
            }
          }

          return result;
        });
        // setFilteredOptions(_filteredOptions);
        setFilteredOptions(_filteredOptions);
      }
    },
    [options, searchQuery, optionsFilters],
    500
  );

  /**
   * Set optionsFilters
   */
  useEffect(() => {
    if (filters === undefined) return;

    setOptionsFilters(filters);
  }, [filters]);

  //#endregion

  useClickOutside([buttonElement, popperElement], handleClickOutside);

  return (
    !isNull(filteredOptions) ? (
      <div
        {...other}
        className={`${className} popover z-20`}
        style={{ ...style, ...styles.popper }}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
        ref={setPopperElement}
        {...attributes.popper}
      >
        <div
          className={`bg-gray-50 border-gray-200 shadow-md rounded-md border ml-2 mr-2`}
        >
          {showSearch && (
            <div className="border-b px-4 py-4">
              {title && title}
              <Input
                id="search"
                placeholder="Filter..."
                value={searchQuery}
                setValue={handleChangeSearchQuery}
                required={false}
                addonLeft={<SearchIcon className="w-4" />}
                addonRight={
                  searchQuery && (
                    <XIcon className="w-4" onClick={() => setSearchQuery("")} />
                  )
                }
                addonRightInteractable={true}
                size="sm"
                disableCalcTrigger={true}
                label={undefined}
                hint={undefined}
                validate={undefined}
                setValid={undefined}
                labelSize={undefined}
                defaultValue={undefined}
                theme={undefined}
              />
              {!isNullEmptyOrWhitespace(optionsFilters) && (
                <div className="text-sm mt-2">
                  <span className="mr-2 text-gray-500">Filtered by:</span>
                  {optionsFilters !== undefined && Object.entries(optionsFilters).map(([key, value]) =>
                    value.map((v: string) => (
                      <Badge
                        key={`badge-${key}-${v}`}
                        className="mr-1 mb-1 cursor-pointer"
                        theme="secondary"
                        iconPosition="right"
                        icon={<XIcon className="w-4 h-4" />}
                        onClick={() => removeFilter(key, v)}
                        title="Click to remove filter"
                      >
                        {key}: {v}
                      </Badge>
                    ))
                  )}
                </div>
              )}
            </div>
          )}
          <div className="">
            <List {...optionProps} theme="striped">
              {filteredOptions?.map((option: IOption) => (
                <ListItem
                  {...option}
                  id={`menu-item-${option.id}`}
                  key={`menu-item-${option.id}`}
                  onClick={(ev: React.MouseEvent<HTMLElement>) => handleOptionOnClick(ev, option)}
                >
                  {option.text}
                </ListItem>
              ))}
            </List>
            {showStats && filteredOptions && options && (
              <div className="text-xs text-gray-500 p-4 text-center">
                Showing <strong>{filteredOptions.length}</strong> of{" "}
                <strong>{options.length}</strong> records
              </div>
            )}
          </div>
        </div>
        <div
          ref={setArrowElement}
          className="arrow text-gray-50"
          style={styles.arrow}
        >
          <svg viewBox="0 0 100 100">
            <polyline
              points="50 15, 100 100, 0 100"
              style={{ stroke: "currentColor", strokeWidth: 1 }}
            />
            <polygon
              points="50 15, 100 100, 0 100"
              style={{
                fill: "currentColor",
                stroke: "currentColor",
                strokeWidth: 1,
              }}
            />
          </svg>
        </div>
      </div>
    ) : null
  );
}

function someValueInMeta(value: any, opt: IOption, key: string) {
  if (isNullEmptyOrWhitespace(value)) return false;
  if (opt.meta === undefined || opt.meta[key] === undefined) {
    return false;
  }

  if (value instanceof Array) {
    // Array
    return value.some((v) => {
      if (opt.meta?.[key] instanceof Array) {
        // Array - e.g. ["1", "2", "3"]
        return opt.meta[key].some((r:string) => r.toLowerCase() === v.toLowerCase());
      } else {
        // String
        return v.toLowerCase() === opt.meta?.[key].toLowerCase();
      }
    });
  }
  // String
  return value.toLowerCase().includes(opt.meta[key].toLowerCase());
}

import Breadcrumb from "components/Breadcrumb";
import { List, ListItem } from "components/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppDataContext } from "context/AppDataProvider";
import { redirectToFormView } from "helpers/redirectUtilities";
import ButtonNew from "components/Historical/ButtonNew";
import useDeepCompareEffect from "use-deep-compare-effect";
import Card from "components/Card";
import ButtonSkeleton from "components/core/Buttons/ButtonSkeleton";

export default function ListPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { moduleName, formType } = useParams();

  const [newButtonOptions, setNewButtonOptions] = useState(undefined);

  const { forms, dashboards, refetchDashboards } = useContext(AppDataContext);

  const filteredForms = useMemo(
    () =>
      forms.filter(
        (f) => f.FormType?.toLowerCase() === formType?.toLowerCase()
      ),
    [forms, formType]
  );

  /**
   * Refetch dashboards to update cache
   */
  useEffect(() => {
    refetchDashboards();
  }, [refetchDashboards]);

  /**
   * Set new button options
   */
  useDeepCompareEffect(() => {
    const _forms = filteredForms.filter(
      (f) =>
        f.Permissions.includes("view") &&
        f.Permissions.includes("create") &&
        f.FormType?.toLowerCase() === formType?.toLowerCase()
    );

    setNewButtonOptions(
      _forms.map((f) => ({
        FormName: f.FormName,
        FormTitle: f.FormTitle,
        FormType: f.FormType,
      }))
    );
  }, [filteredForms, formType]);

  //#region Event Handlers

  function handleButtonNewClick(formType, formId) {
    return navigate(redirectToFormView(location, moduleName, formType, formId));
  }

  //#endregion

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          menu={props.menu}
          showHome={false}
          menuRequired={true}
          farmRequired={false}
          houseRequired={true}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <div className="mb-2 flex flex-row items-center">
            <div className="text-lg text-gray-600 uppercase flex-grow font-medium">
              Dashboards
            </div>
            {newButtonOptions !== undefined && filteredForms.length > 0 ? (
              // <div className="absolute bottom-4 right-4">
              <ButtonNew
                className="justify-end"
                onClick={handleButtonNewClick}
                options={newButtonOptions}
              />
            ) : (
              // </div>
              <ButtonSkeleton />
            )}
          </div>
        </div>
        <div className="col-span-full">
          <Card>
            {dashboards?.length > 0 ? (
              <List theme="striped" size="small">
                {dashboards.map((dashboard) => (
                  <DashboardListItem
                    key={dashboard.id}
                    dashboard={dashboard}
                    formId="core"
                  />
                ))}
              </List>
            ) : (
              <div className="flex flex-grow items-center justify-center">
                <div className="flex-grow items-center justify-center text-sm text-center italic">
                  <div className="p-2">
                    <div>
                      <p>No records found.</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </main>
  );
}

const DashboardListItem = ({ dashboard, formId, ...other }) => {
  const navigate = useNavigate();
  let location = useLocation();
  const { moduleName, formType } = useParams();

  //#region Handlers

  /**
   * Handle list item click
   */
  const handleClick = (id) => {
    return navigate(
      redirectToFormView(location, moduleName, formType, formId, null, null, id)
    );
  };

  //#endregion

  return (
    <ListItem {...other} onClick={() => handleClick(dashboard.id)}>
      <div className="min-w-0 flex-1 flex items-center">
        {/* <ListItemIcon
          dataStatus={formValues?.Status}
          date={formValues.DateApplies}
          dateToday={dateToday}
          loaded={!!formValues?.Status}
        /> */}
        <div className="flex-grow">
          <div className="mb-0.5 text-primary font-medium">
            {dashboard.title}
          </div>

          <div className="text-xs text-gray-500">
            <div className="flex space-x-2">
              {dashboard.farmGroups && (
                <div>Farm group(s): {dashboard.farmGroups}</div>
              )}
              {dashboard.dataSources && (
                <div>Data source(s): {dashboard.dataSources}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

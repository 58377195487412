import classNames from "classnames";
import { BadgeSeverity } from "components/forms/BadgeSeverity";
import PropTypes from "prop-types";

const DescriptionList = ({
  items,
  striped: isStriped = true,
  className,
  onClickImage,
  ...other
}) => {
  return (
    <dl
      {...other}
      className={classNames(className, "print:table print:w-full")}
    >
      {items.map(({ id, title, value, className, ...other }, index) => (
        <div
          key={id}
          className={classNames(
            className,
            isStriped && index % 2 ? "bg-gray-50" : "bg-transparent",
            "px-4 py-3 tablet:grid tablet:grid-cols-4 tablet:gap-4 laptop:grid-cols-6 tablet:px-6 print:table-row page-break"
          )}
        >
          <dt className="text-sm font-medium text-gray-500 title tablet:col-span-2 print:table-cell print:px-4 print:py-3">
            {title}
          </dt>
          <dd className="mt-1 tablet:mt-0 text-sm text-gray-900 sm:mt-0 col-span-2 tablet:col-span-2 laptop:col-span-4 print:mx-0 value print:table-cell print:px-4 print:py-3">
            {other?.type === "images" ? (
              <div className="flex-shrink-0 sm:mt-0 sm:ml-5">
                <div className="flex overflow-hidden -space-x-1">
                  {value?.map((imgUrl) => (
                    <div
                      onClick={() => {
                        onClickImage !== undefined && onClickImage(imgUrl);
                      }}
                      className="block h-12 w-12 rounded-full cursor-pointer bg-gray-100 border-2 border-white ring-1 ring-white hover:border-primary relative hover:z-10 overflow-hidden print:bg-transparent"
                    >
                      <img
                        key={imgUrl}
                        className="object-cover min-h-full min-w-full pointer-events-none print:w-2/3 print:mx-auto"
                        src={imgUrl}
                        alt={imgUrl}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : other?.type === "status" ? (
              <BadgeSeverity severity={other?.statusColor}>
                {value}
              </BadgeSeverity>
            ) : (
              value
            )}
          </dd>
        </div>
      ))}
    </dl>
  );
};

DescriptionList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      value: PropTypes.any,
      className: PropTypes.string,
    })
  ),
};

export default DescriptionList;

import classNames from "classnames";
import { useState, useEffect } from "react";

const DescriptionList = ({ itemCount = 6 }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems([...Array(itemCount).keys()]);
  }, [itemCount]);

  return (
    <dl className="animate-pulse">
      {items.map((item, index) => (
        <div
          className={classNames(
            index % 2 ? "bg-gray-50" : "bg-transparent",
            "px-4 py-3 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:px-6 print:grid print:grid-cols-3 print:gap-0 print:px-2 print:py-2"
          )}
        >
          <dt className="text-sm font-medium text-gray-500 title">
            <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
          </dt>
          <dd className="mt-1 tablet:mt-0 text-sm text-gray-900 sm:mt-0 col-span-2 print:mx-0 value">
            <div className="mt-1 h-2 bg-gray-300 rounded"></div>
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default DescriptionList;

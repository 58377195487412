import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./applicationInsights";
import ReactGA from "react-ga";
import { ProvideAuth } from "hooks/useAuth";
import { NotificationProvider } from "context/NotificationProvider";
import {
  AppDataProvider,
  AppDataContext,
  useMenus,
} from "context/AppDataProvider";
import { NavProvider } from "context/NavProvider";
import Navbar from "components/Navbar";
import LoginPage from "pages/user/login";
import SchedulePage from "pages/SchedulePage";
import HomePage from "pages/index";
import FormsPage from "pages/farms/FormsPage";
import ListPage from "pages/farms/ListPage";
import Notifications from "components/Notifications";
import AppUpdateNotification from "components/AppUpdateNotification";
import Error404Page from "pages/Error404";
import ScrollToTop from "components/ScrollToTop";
import DashboardListPage from "pages/dashboards";
import DashboardBuilderPage from "pages/dashboards/builder";
import DashboardBuilderListPage from "pages/dashboards/builder/ListPage";
import AdminNonConformanceList from "pages/admin/Audit/NonConformance/ListPage";
import { localDate } from "helpers/dateUtilities";
import AuditReportPage from "pages/farms/AuditReportPage";
import ReportFormPage from "pages/ReportForm";
import { ModalProvider } from "context/ModalProvider";
import Sidebar from "components/Sidebar";
import AdminAuditListPage from "pages/admin/Audit";
import SidebarProvider from "context/SidebarProvider";
import classNames from "classnames";
import { useTheme } from "hooks/useTheme";
import Settings from "components/Settings";
import Error500 from "pages/Error500";

const version = require("../package.json").version;
export const AppVersion = `v${version}`;

export default function App() {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(undefined);
  const [showSettings, setShowSettings] = useState(false);

  const [theme, setTheme] = useTheme();

  useEffect(() => {
    document.addEventListener(
      "serviceWorkerUpdateAvailable",
      handleServiceWorkerUpdateAvailable
    );

    return () =>
      document.removeEventListener(
        "serviceWorkerUpdateAvailable",
        handleServiceWorkerUpdateAvailable
      );
  }, []);

  const handleServiceWorkerUpdateAvailable = (ev) => {
    setWaitingWorker(ev.detail.waiting);
    setUpdateAvailable(true);
  };

  const handleUpdateApp = () => {
    // Call skip waiting to automatically update app.
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setUpdateAvailable(false);
    waitingWorker.addEventListener("statechange", (e) => {
      // Wait for new service worker to become active
      if (e.target.state === "activated") {
        window.location.reload();
      }
    });
  };

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={(error) => (
          <Error500 />
        )}
        appInsights={reactPlugin}
      >
        <AppDataProvider>
          <SidebarProvider>
            <NavProvider>
              <NotificationProvider>
                <ModalProvider>
                  <ProvideAuth>
                    <Notifications />
                    <AppUpdateNotification
                      show={updateAvailable}
                      onClick={handleUpdateApp}
                    />
                    <Router>
                      <ScrollToTop />
                      <div id="app" className={classNames(theme)}>
                        <div
                          className={classNames(
                            "bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-200 min-h-screen font-sans text-base flex flex-col antialiased overflow-x-hidden"
                          )}
                        >
                          <Navbar theme={theme} />
                          <Sidebar
                            position="left"
                            theme={theme}
                            setTheme={setTheme}
                            setShowSettings={setShowSettings}
                          />
                          <Settings
                            open={showSettings}
                            setOpen={setShowSettings}
                            settings={[
                              {
                                id: "theme",
                                value: theme,
                                setValue: setTheme,
                                label: "Theme",
                                type: "toggle",
                                checkedValue: "dark",
                                uncheckedValue: "light",
                              },
                            ]}
                          />

                          <CustomRoutes />

                          <div className="py-2 flex items-center justify-center">
                            <p className="text-xs text-gray-400">
                              &copy; Unitas Software {localDate().getFullYear()}.
                              {AppVersion}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Router>
                  </ProvideAuth>
                </ModalProvider>
              </NotificationProvider>
            </NavProvider>
          </SidebarProvider>
        </AppDataProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

function CustomRoutes() {
  usePageViews();

  return (
    <Routes>
      <Route path="/user/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route
        path="/:moduleName"
        element={
          <RequireAuth>
            <FormListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/:moduleName/:formType"
        element={
          <RequireAuth>
            <FormListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/:moduleName/:formType/:formId"
        element={
          <RequireAuth>
            <FormPage />
          </RequireAuth>
        }
      />
      <Route
        path="/:moduleName/:formType/:formId/:view"
        element={
          <RequireAuth>
            <FormPage />
          </RequireAuth>
        }
      />
      <Route
        path="/:moduleName/:formType/:formId/:view/:id"
        element={
          <RequireAuth>
            <FormPage />
          </RequireAuth>
        }
      />
      <Route
        path="/:moduleName/:formType/:formId/:view/:parentFormId/:parentId"
        element={
          <RequireAuth>
            <FormPage />
          </RequireAuth>
        }
      />
      <Route
        path="/:moduleName/:formType/:formId/:view/:id/:parentFormId/:parentId"
        element={
          <RequireAuth>
            <FormPage />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}

export function RequireAuth({ children, ...rest }) {
  const { isSignedIn } = useContext(AppDataContext);
  const location = useLocation();

  if (isSignedIn) {
    return <PageExists>{children}</PageExists>;
  }

  return <Navigate to="/user/login" replace state={{ from: location }} />;
}

function PageExists({ children, ...rest }) {
  const { user } = useContext(AppDataContext);
  const { menus, setActiveMenu } = useMenus();
  let { formType, moduleName } = useParams();

  const menu = React.useMemo(() => {
    let _moduleName = moduleName?.toLowerCase();
    if (_moduleName === undefined) {
      _moduleName = "core";
    }

    const parentMenu = menus.find(
      (m) => m.Alias.toLowerCase() === _moduleName && m.Level === "0"
    );
    if (!parentMenu) {
      return undefined;
    }

    let _formType = formType?.toLowerCase();
    if (_formType === undefined) {
      // Core module feature
      _formType = "core";
    }

    const menu = parentMenu.Children.find(
      (m) => m.Alias.toLowerCase() === _formType
    );

    if (!menu) {
      return undefined;
    }

    const result = {
      ...menu,
      Parent: parentMenu,
    };

    return result;
  }, [moduleName, menus, formType]);

  useEffect(() => {
    if (menu?.ID === undefined || user?.UserName === undefined) {
      return;
    }
    saveRecentVisit(user?.UserName, menu.ID);
  }, [menu?.ID, user?.UserName]);

  useEffect(() => {
    if (menu?.ID === undefined) {
      return;
    }
    setActiveMenu(menu);
  }, [menu, setActiveMenu]);

  return React.cloneElement(children, { menu });
}

function FormListPage(props) {
  const { formType, moduleName } = useParams();
  const _formType = formType?.toLowerCase();
  const _moduleName = moduleName?.toLowerCase();
  // const _view = view?.toLowerCase();

  // if (params.formType.toLowerCase() === "production") {
  //   if (params.view === "calendar") {
  //     return <SchedulePage />;
  //   }

  //   return <Error404Page />;
  // }

  if (_moduleName === "farm" && _formType === "production") {
    return <SchedulePage {...props} />;
  }

  if (_moduleName === "admin" && _formType === "dashboard") {
    return <DashboardBuilderListPage {...props} />;
  }

  if (_moduleName === "dashboard" && _formType === "dashboard") {
    return <DashboardListPage {...props} />;
  }

  if (_moduleName === "admin" && _formType === "audit") {
    return <AdminAuditListPage {...props} />;
  }

  if (_moduleName === "reporting" && _formType === "report") {
    return <ReportFormPage {...props} />;
  }

  return <ListPage {...props} />;
}

function FormPage(props) {
  const { formType, moduleName, formId, view, id } = useParams();
  const _formType = formType?.toLowerCase();
  const _moduleName = moduleName?.toLowerCase();
  const _formId = formId?.toLowerCase();
  const _view = view?.toLowerCase();

  if (_moduleName === "admin" && _formType === "dashboard") {
    return <DashboardBuilderPage {...props} />;
  }

  if (
    _moduleName === "admin" &&
    _formType === "audit" &&
    _formId === "nonconformances"
  ) {
    return <AdminNonConformanceList {...props} />;
  }

  if (_formType === "audit" && _view === "report" && id !== undefined) {
    return <AuditReportPage {...props} />;
  }

  return <FormsPage {...props} />;
}

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== "test") {
      // Don't run during tests
      ReactGA.pageview(location.pathname);
    }
  }, [location]);
}

function saveRecentVisit(userName, menuId) {
  if (!userName || !menuId) {
    return;
  }

  const recentVisits = localStorage.getItem(`recentVisits-${userName}`);
  const recentVisitsJson = JSON.parse(recentVisits || "[]");
  const recentVisitsUpdated = recentVisitsJson
    .filter((r) => r !== menuId)
    .slice(0, 3);
  recentVisitsUpdated.unshift(menuId);
  localStorage.setItem(
    `recentVisits-${userName}`,
    JSON.stringify(recentVisitsUpdated)
  );
}

import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ListItem } from "components/core";
import ListItemIcon from "components/Schedule/ListItemIcon";
import ListItemStatus from "components/Schedule/ListItemStatus";
import { dateToString } from "helpers/dateUtilities";
import { redirectToFormView } from "helpers/redirectUtilities";

export default function ListItemDaily({
  todayEntries,
  date,
  dateToday,
  formId,
  className,
  onClick,
  ...other
}) {
  let { formType, moduleName } = useParams();

  let navigate = useNavigate();
  const location = useLocation();

  const [entry, setEntry] = useState(undefined);

  const abortControllerRef = useRef(undefined);

  //#region Callbacks

  /**
   * Handle list item click
   */
  const handleClick = () => {
    const recordId = date.getTime();

    return navigate(
      redirectToFormView(
        location,
        moduleName,
        formType,
        formId,
        null,
        null,
        recordId
      )
    );
  };

  //#endregion

  //#region Side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      return () => abortControllerRef.current.abort();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Set entry
   */
  useEffect(() => {
    if (!todayEntries) return;

    const _newEntry = todayEntries?.find(
      (e) => e.FormName.toLowerCase() === "production"
    );
    setEntry(_newEntry ?? null);
  }, [todayEntries]);

  //#endregion

  return (
    <ListItem aria-label="daily" onClick={() => handleClick()} {...other}>
      <ListItemIcon
        dataStatus={entry?.Status}
        date={date}
        dateToday={dateToday}
      />
      <div className="flex-grow">
        <div className="font-medium">Daily</div>
        <div className="flex text-sm">
          <ListItemStatus
            dataStatus={entry?.Status}
            date={date}
            dateToday={dateToday}
          />
        </div>
        {entry && entry._LastModified && (
          <div className="text-xs text-gray-400">
            Updated:{" "}
            {dateToString(entry._LastModified.localised, {
              includeTime: true,
            })}
          </div>
        )}
      </div>
    </ListItem>
  );
}

import { CHART_TYPE } from "constants.js";
import { v4 as uuid } from "uuid";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";

/**
 * Format data for specific chart type
 * @param {import("components/Dashboard/types").IChart} chart
 * @param {any[]}  data  - Raw form data.
 * @param {{ id: String, type: String }}  chart  - Chart object of chart to format data for.
 */
export const formatChartData = (chart, data, standards) => {
  let result = {
    data: [],
    standardData: [],
  };
  if (!data?.length) return result;

  // Filter 'age' data by selected datasources
  const allowedDataSourcesSet = new Set();
  for (const key of chart.keys) {
    const dataSource = key.id.includes(":") ? key.id.split(":")[0] : undefined;
    if (!!dataSource) allowedDataSourcesSet.add(dataSource);
  }
  const allowedDataSources = Array.from(allowedDataSourcesSet);

  // Data
  const chartKeys = chart.keys.map((key) => key.id);
  const tooltipKeys = chart.tooltipKeys?.map((k) => k.id) ?? [];
  const filteredData = filterDataByChartKeys(
    data,
    chartKeys,
    tooltipKeys,
    allowedDataSources
  );

  // Index by first key
  const chartIndexBy = chartKeys.length > 0 ? chartKeys[0] : null;

  // Standards
  const standardIndexBy = chartIndexBy
    // replace datasource
    ?.replace(/^(.*:)(.*)$/, "$2");

  // if (
  //   !isNullEmptyOrWhitespace(standardIndexBy) &&
  //   standards?.length &&
  //   standardIndexBy in standards[0] === false
  // ) {
  //   debugger
  //   // Standard index by should exist in 'standards' data
  //   throw new Error(`${standardIndexBy} does not exist in standards`);
  // }
  const chartStandardKeys = chart.standardKeys?.map((k) => k.id) ?? [];
  const filteredStandards = filterStandardsByStandardKeys(
    standards,
    chartStandardKeys,
    standardIndexBy
  );

  if (chart.type === CHART_TYPE.BAR) {
    // Chart data
    result.data = filteredData.map((obj) => {
      return {
        ...obj,
        tooltip: Object.fromEntries(
          chart.tooltipKeys?.map((k) => {
            return [k.title, obj[k.id]];
          }) ?? []
        ),
        id: uuid(), // react 'key'
      };
    });

    // Remove any values/properties we don't want to show here
    // E.g. newData.forEach((x) => { delete x["Daily Birds Alive"]; });
  } else if (chart.type === CHART_TYPE.LINE) {
    // LINE CHART
    /**
     * Line Chart data, which must conform to this structure:
     * Array<{
     *   id:   string | number
     *   data: Array<{
     *     x: number | string | Date
     *     y: number | string | Date
     *   }>
     * }>
     * @see: https://nivo.rocks/line/
     */

    filteredData.sort((a, b) => a[chartIndexBy] - b[chartIndexBy]);

    // Chart data
    for (const key of chart.keys.filter((k, i) => i > 0)) {
      const flatValues = filteredData.flatMap((x) => ({
        x: x[chartIndexBy],
        y: x[key.id],
        tooltip: Object.fromEntries(
          chart.tooltipKeys?.map((k) => {
            return [k.title, x[k.id]];
          }) ?? []
        ),
      }));

      result.data.push({
        id: key.id,
        data: flatValues,
      });
    }

    // Standard data
    for (const key of chartStandardKeys) {
      const flatValues = filteredStandards.flatMap((x) => {
        return {
          x: x[standardIndexBy],
          y: x[key],
        };
      });

      result.standardData.push({
        id: key,
        data: flatValues,
      });
    }
  } else if (chart.type === CHART_TYPE.SCATTER) {
    filteredData.sort((a, b) => a[chartIndexBy] - b[chartIndexBy]);

    // Chart data
    for (const key of chart.keys.filter((k, i) => i > 0)) {
      const flatValues = filteredData.flatMap((x) => ({
        x: x[chartIndexBy],
        y: x[key.id],
        tooltip: Object.fromEntries(
          chart.tooltipKeys?.map((k) => {
            return [k.title, x[k.id]];
          }) ?? []
        ),
      }));

      result.data.push({
        id: key.id,
        data: flatValues,
      });
    }

    // Standard data
    for (const key of chartStandardKeys) {
      const flatValues = filteredStandards.flatMap((x) => {
        return {
          x: x[standardIndexBy],
          y: x[key],
        };
      });

      result.standardData.push({
        id: key,
        data: flatValues,
      });
    }
  } else if (chart.type === CHART_TYPE.TABLE) {
    result.data = filteredData.map((obj) => {
      return {
        ...obj,
        id: uuid(), // react 'key'
      };
    });

    // Remove any values/properties we don't want to show here
    // E.g. newData.forEach((x) => { delete x["Daily Birds Alive"]; });
  } else if (chart.type === CHART_TYPE.METRIC) {
    result.data = filteredData
      .map((r) => ({
        ...r,
        id: uuid(), // Ensures that each record set displays on chart, regardless of match index
      }))
      .slice(-1); // Fetch only the last record
  } else if (chart.type === CHART_TYPE.TREND) {
    result.data = filteredData
      .map((r) => ({
        ...r,
        id: uuid(), // Ensures that each record set displays on chart, regardless of match index
      }))
      .slice(-2) // last too records only
      .reverse();
  }

  return result;
};

function filterDataByChartKeys(
  data,
  chartKeys,
  tooltipKeys,
  allowedDataSources
) {
  const filteredData = [];
  for (const obj of data) {
    const newProperties = Object.keys(obj)
      ?.filter((key) => chartKeys.includes(key) || tooltipKeys.includes(key))
      .reduce((res, key) => {
        res[key] = obj[key].value;
        return res;
      }, {});

    // does property have applicable datasource properties
    const hasDataSourceProperty =
      allowedDataSources.length === 0 ||
      Object.keys(newProperties).some((property) =>
        allowedDataSources.includes(property.split(":")[0])
      );

    if (hasDataSourceProperty && Object.keys(newProperties).length > 0) {
      filteredData.push(newProperties);
    }
  }
  return filteredData;
}

function filterStandardsByStandardKeys(
  standards,
  chartStandardKeys,
  standardIndexBy
) {
  const filteredStandards = [];
  for (const obj of standards) {
    const newProperties = Object.keys(obj)
      ?.filter(
        (key) => chartStandardKeys.includes(key) || key === standardIndexBy
      )
      .reduce((res, key) => {
        res[key] = obj[key].value;
        return res;
      }, {});

    if (Object.keys(newProperties).length > 0) {
      filteredStandards.push(newProperties);
    }
  }
  return filteredStandards;
}

/**
 * Convert JSON object to metrics format
 */
export function extractMetricsFromJsonObjs(jsonObjs) {
  if (isNull(jsonObjs)) return jsonObjs;

  // Build array of distinct metrics
  // It's important to go through full array as some objects contain different properties
  const disallowedKeys = [];
  const metricsMap = new Map();
  for (const obj of jsonObjs) {
    for (const key in obj) {
      // Skip if already set
      const splitKey = key.split(":");
      const keyWithDataSourceRemoved = splitKey[1] ?? key;
      const dataSource = splitKey.length > 1 ? splitKey[0] : undefined;
      const isDisallowedKey = disallowedKeys.includes(keyWithDataSourceRemoved);
      if (!isDisallowedKey && !metricsMap.has(key)) {
        metricsMap.set(key, {
          id: key,
          title: obj[key].title,
          dataSource,
        });
      }
    }
  }

  return Array.from(metricsMap.values());
}

export function formatChartAttrs(chart) {
  let _newAttrs = { ...chart.attrs };
  if (chart.type === CHART_TYPE.BAR || chart.type === CHART_TYPE.LINE) {
    _newAttrs = {
      ..._newAttrs,
      axis: {
        ..._newAttrs.axis,
        bottom: {
          ..._newAttrs.axis?.bottom,
          legend: chart.keys.length > 0 ? chart.keys[0].title : null,
        },
        left: {
          ..._newAttrs.axis?.left,
          legend: chart.keys.length > 1 ? chart.keys[1].title : null,
        },
        right: {
          ..._newAttrs.axis?.right,
          legend: chart.keys.length > 2 ? chart.keys[2].title : null,
        },
      },
    };
  }

  return _newAttrs;
}

export function filterUserDashboardsByFarm(dashboards, user, farm) {
  if (!dashboards?.length) {
    return undefined;
  }

  const result = dashboards.filter((d) => {
    // filter by userGroups
    if (!isNullEmptyOrWhitespace(d.userGroups)) {
      const allowedUserGroups = d.userGroups
        .split(",")
        .map((ug) => ug.toLowerCase());
      if (
        !allowedUserGroups.includes(
          user.PermissionLevel?.toString().toLowerCase()
        )
      ) {
        return false;
      }
    }

    // filter by farm group
    if (!isNullEmptyOrWhitespace(d.farmGroups)) {
      const allowedFarmGroups = d.farmGroups
        .split(",")
        .map((fg) => fg.toLowerCase());
      if (!allowedFarmGroups.includes(farm.FarmGroup.toLowerCase())) {
        return false;
      }
    }

    return true;
  });

  return result;
}

export function filterUserDashboardsByMenuId(dashboards, user, farms, menuId) {
  if (
    !dashboards?.length ||
    !farms?.length ||
    user?.PermissionLevel === undefined ||
    menuId === undefined
  ) {
    return undefined;
  }

  const result = dashboards.filter((d) => {
    // filter by menu id
    if (!isNullEmptyOrWhitespace(menuId)) {
      if (d.menuId?.toString() !== menuId?.toString()) {
        return false;
      }
    }

    // filter by userGroups
    if (!isNullEmptyOrWhitespace(d.userGroups)) {
      const allowedUserGroups = d.userGroups
        .split(",")
        .map((ug) => ug.toLowerCase());
      if (
        !allowedUserGroups.includes(
          user.PermissionLevel?.toString().toLowerCase()
        )
      ) {
        return false;
      }
    }

    // filter by farm groups
    // Keep this at the bottom, if possible, as it is the most expensive operation
    if (!isNullEmptyOrWhitespace(d.farmGroups)) {
      const userFarmGroups = farms.reduce((acc, farm) => {
        const normalisedFarmGroup = farm.FarmGroup?.toLowerCase();
        if (normalisedFarmGroup && !acc.includes(normalisedFarmGroup)) {
          acc.push(normalisedFarmGroup);
        }
        return acc;
      }, []);

      const allowedFarmGroups = d.farmGroups
        .split(",")
        .map((fg) => fg.toLowerCase());
      if (!allowedFarmGroups.some((fg) => userFarmGroups.includes(fg))) {
        return false;
      }
    }

    return true;
  });

  return result.length > 0 ? result : undefined;
}

export function getUserDashboardById(dashboards, id) {
  if (!dashboards?.length) {
    return undefined;
  }

  const result = dashboards.find((d) => d.id === id);

  return result;
}

export function getMaxXValue(data) {
  const maxArray = data.map((data) =>
    data.data.reduce((max, p) => {
      const x = Number(p.x);
      return x > max ? x : max;
    }, data.data[0].x)
  );

  const max = Math.max(...maxArray);

  return max;
}

export function getMinXValue(data) {
  const minArray = data.map((data) =>
    data.data.reduce((min, p) => {
      const x = Number(p.x);
      return x < min ? x : min;
    }, data.data[0].x)
  );

  const min = Math.min(...minArray);

  return min;
}

export function getMaxYValueWithHeadroom(data) {
  const max = getMaxYValue(data);

  return max * 1.2;
}

export function getMaxYValue(data) {
  const maxArray = data.map((d) =>
    d.data.reduce((max, p) => {
      const y = Number(p.y);
      return y > max ? y : max;
    }, d.data[0].y)
  );

  const max = Math.max(...maxArray);

  // console.log(data, max)

  return max;
}

export function getMinYValue(data) {
  const minArray = data.map((data) =>
    data.data.reduce((min, p) => {
      const y = Number(p.y);
      return y < min ? y : min;
    }, data.data[0].y)
  );

  const min = Math.min(...minArray);

  return min;
}

import { useState } from "react";
import FormField from "./FormField";
import { Fieldset as FieldsetCore } from "components/core";
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from "assets/icons";
import { Button } from "components/core";
import { isNullEmptyOrWhitespace, isNull } from "helpers/stringUtilities";
import GroupedFields from "components/forms/GroupedFields";
import useDeepCompareEffect from "use-deep-compare-effect";

export default function Fieldset({
  title = "General",
  text = "This section contains general form info.",
  content,
  form,
  groupedFields,
  fields,
  penNumber = 1,
  loaded,
  formValues = {},
  children,
  collapsible = false,
  ...fieldProps
}) {
  const [open, setOpen] = useState(penNumber === 1 ? true : false);

  //#region Side-effects

  /**
   * Set open/closed
   */
  useDeepCompareEffect(() => {
    if (!formValues?.Values?.length) return;

    const filteredFormValues = formValues.Values.filter(
      (value) => !["pwaid", "dateapplies"].includes(value.Ref?.toLowerCase())
    );

    setOpen(filteredFormValues.length > 0 ? true : false);
  }, [formValues]);

  //#endregion

  //#region Event handlers

  const handleClickOpen = () => {
    if (!setOpen) return;

    setOpen(true);
  };

  const handleClickClose = () => {
    if (!setOpen) return;

    setOpen(false);
  };

  //#endregion

  const indexOfFirstRenderedGroup =
    !isNull(groupedFields) &&
    Object.values(groupedFields).findIndex((gf) => gf.render);

  return (
    <FieldsetCore
      title={title}
      text={text}
      showDivider
      loaded={loaded}
      childWrapperClass="grid grid-cols-4 gap-4"
      isFullWidth={collapsible === true && open === false}
      content={
        collapsible ? (
          !open ? (
            <Button
              className="mt-2"
              theme="text"
              icon={<ArrowCircleDownIcon className="w-4 h-4 mr-1" />}
              onClick={handleClickOpen}
            >
              Show pen
            </Button>
          ) : (
            <>
              <Button
                className="mt-2"
                theme="text"
                icon={<ArrowCircleUpIcon className="w-4 h-4 mr-1" />}
                onClick={handleClickClose}
              >
                Hide pen
              </Button>
              {content}
            </>
          )
        ) : (
          content
        )
      }
    >
      {!collapsible || open
        ? !isNull(groupedFields)
          ? Object.entries(groupedFields).map(([key, group], groupIndex) => {
              if (key !== "null") {
                // Grouped fields
                return (
                  <GroupedFields
                    key={`groupedfield-${key}`}
                    showDivider={indexOfFirstRenderedGroup !== groupIndex}
                    render={group.render}
                  >
                    {group.fields.map((field, fieldIndex) => (
                      <FormField
                        {...fieldProps}
                        key={`${field.Ref}-${field.Level}${penNumber}`}
                        field={field}
                        formValues={formValues}
                        penNumber={penNumber}
                        groupFieldIndex={
                          !isNullEmptyOrWhitespace(key) ? fieldIndex : null
                        }
                      />
                    ))}
                  </GroupedFields>
                );
              } else {
                // Indivdual fields amoungst grouped fields
                return group.fields.map((field, fieldIndex) => (
                  <FormField
                    {...fieldProps}
                    key={`${field.Ref}-${field.Level}${penNumber}`}
                    field={field}
                    formValues={formValues}
                    penNumber={penNumber}
                    groupFieldIndex={
                      !isNullEmptyOrWhitespace(key) ? fieldIndex : null
                    }
                  />
                ));
              }
            })
          : fields?.map((field) => (
              <FormField
                {...fieldProps}
                key={`${field.Ref}-${field.Level}${penNumber}`}
                field={field}
                formValues={formValues}
                penNumber={penNumber}
              />
            ))
        : null}
    </FieldsetCore>
  );
}

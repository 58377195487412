/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from "@heroicons/react/outline";
import { XCircleIcon } from "assets/icons";
import { ExclamationCircleIcon } from "assets/icons/ExclamationCircleIcon";
import classNames from "classnames";

interface IAlertProps {
  className?: string;
  theme?: "danger" | "success" | "warning";
  children: React.ReactNode;
}

export default function Alert({ theme, children, ...rest }: IAlertProps) {
  return (
    <div
      {...rest}
      className={classNames(
        theme === "danger" && "text-danger-600",
        theme === "success" && "text-success-600",
        theme === "warning" && "text-warning-600"
      )}
    >
      <div className="flex">
        <div className="shrink-0">
          {theme === "danger" && (
            <XCircleIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
          {theme === "success" && (
            <CheckCircleIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
          {theme === "warning" && (
            <ExclamationCircleIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3">{children}</div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { Label } from "components/core";
import Addon from "./Addon";
import { ExclamationCircleIcon } from "assets/icons";
import { SelectOption } from "./SelectOption";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useCalcTrigger from "hooks/useCalcTrigger";
import { IListOption } from "helpers/formUtilities";

interface ISelectProps {
  label: string;
  id: string;
  hint?: string;
  validate?: (value: any) => boolean;
  value: any;
  setValue: (value: any) => void;
  setValid?: (
    valid: boolean,
    value: any,
    required: boolean,
    complete: boolean
  ) => void;
  required?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
  labelPosition?: "top" | "left" | "inset";
  labelSize?: "large" | "small";
  addonLeft?: React.ReactNode;
  addonRight?: React.ReactNode;
  dependencies?: string[];
  defaultValue?: string;
  render?: boolean;
  placeholder?: string;
  listOptions?: IListOption[];
  disableCalcTrigger?: boolean;
}

const Select = ({
  label,
  id,
  hint = undefined,
  validate = undefined,
  value,
  setValue,
  setValid = undefined,
  required = true,
  isFullWidth = false,
  disabled = false,
  labelPosition = "top",
  labelSize = undefined,
  addonLeft = undefined,
  addonRight = undefined,
  dependencies = [],
  defaultValue = undefined,
  render = true,
  placeholder = "- Select - ",
  listOptions = undefined,
  disableCalcTrigger = false,
  ...other
}: ISelectProps) => {
  useCalcTrigger(value, setValue, disableCalcTrigger);

  const [touched, setTouched] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(value?.length > 0);

  const inputErrorClasses = ["border-danger-600"];
  const inputClasses = [
    "rounded-md pt-3 pb-3",
    "border-1",
    "focus:ring-4 focus:ring-offset-0",
    `${disabled ? "disabled:opacity-50" : ""}`,
  ];
  const errorIconClasses = [];

  // Addons
  if (addonLeft) {
    inputClasses.push("pl-10");
  }
  if (addonRight) {
    inputClasses.push("pr-10");
  }

  inputClasses.push(
    "bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
  );
  errorIconClasses.push("bg-white");

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value: any) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      setValue(value);
      setHasValue(value.length > 0);
    },
    [setValue]
  );

  const validation = () => {
    if (validate) return validate(value);
  };

  //#endregion

  //#region Side-effects

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (touched || !defaultValue || !setInputValue) return;

    if (isNullEmptyOrWhitespace(value)) {
      setInputValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult || false);

    const complete = required && isNullEmptyOrWhitespace(value) ? false : true;
    if (setValid) setValid(!validationResult, value, required, complete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, value, required]);

  //#endregion

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div
      className={`${
        labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative"
      }`}
    >
      <Label
        id={id}
        text={label}
        required={required}
        focused={focused}
        hasValue={hasValue}
        position={labelPosition}
        size={labelSize}
        erroneous={touched && error && !focused}
        addonPosition={undefined}
        labelClasses={undefined}
        theme={undefined}
      />
      <div className={`mt-1 ${labelPosition === "left" ? "col-span-2" : ""}`}>
        <div className="rounded-md shadow-sm relative">
          {addonLeft && <Addon position="left">{addonLeft}</Addon>}
          <select
            id={id}
            className={`block w-full tablet:text-sm ${inputClasses.join(" ")} ${
              touched && error && !focused ? inputErrorClasses.join(" ") : ""
            }`}
            value={value ?? ""}
            onBlur={() => {
              setTouched(true);
              setFocused(false);
            }}
            onChange={(ev) => setInputValue(ev.target.value)}
            onFocus={() => setFocused(true)}
            aria-invalid={error}
            disabled={disabled}
            {...other}
          >
            {(!value ||
              !listOptions?.length ||
              listOptions.some(
                (option) => option.Value?.toLowerCase() === value?.toLowerCase()
              ) === false) && (
              <SelectOption value="">{placeholder}</SelectOption>
            )}
            {listOptions?.map((option) => (
              <SelectOption
                key={option.Id ?? option.Value}
                value={option.Value}
              >
                {option.Text}
              </SelectOption>
            ))}
          </select>
          {addonRight && <Addon position="right">{addonRight}</Addon>}
          {touched && error && !focused && (
            <div className="absolute -top-2 right-2 pointer-events-none bg-white px-1">
              <ExclamationCircleIcon className="h-5 w-5 text-danger-500" />
            </div>
          )}
        </div>
        {touched && error && !focused ? (
          <p className="mt-2 text-xs text-danger-600" id="email-error">
            {error}
          </p>
        ) : (
          hint && (
            <p className="mt-2 text-xs text-gray-500" id="email-error">
              {hint}
            </p>
          )
        )}
      </div>
    </div>
  );
};

export { Select };

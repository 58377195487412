import { useMemo } from "react";
import Breadcrumb from "components/Breadcrumb";
import useQuery from "hooks/useQuery";
import { useState, useContext } from "react";
import { AppDataContext, useMenus } from "context/AppDataProvider";
import classNames from "classnames";
import Dashboard from "components/Dashboard";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { filterUserDashboardsByMenuId } from "helpers/dashboardUtilities";
import BreadcrumbItem from "components/core/Breadcrumbs/BreadcrumbItem";
import SectionTitle from "components/SectionTitle";
import FieldSkeleton from "components/core/Forms/FieldSkeleton";

export default function DashboardsListPage() {
  // Get query params
  let query = useQuery();
  let farmId = query.get("farmId");
  let houseId = query.get("houseId");

  const { farms, dashboards, user } = useContext(AppDataContext);

  const { activeMenu } = useMenus();

  const [selectedDashboardId, setSelectedDashboardId] = useState(undefined);

  const userDashboards = useMemo(() => {
    if (
      !dashboards?.length ||
      user?.UserName === undefined ||
      !farms?.length ||
      activeMenu?.ID === undefined
    ) {
      return undefined;
    }

    const farm = farms.find(
      (f) => f.FarmCode?.toLowerCase() === farmId?.toLowerCase()
    );
    if (farm === undefined) {
      return undefined;
    }

    const result = filterUserDashboardsByMenuId(
      dashboards,
      user,
      farms,
      activeMenu.ID
    );

    return result;
  }, [dashboards, farms, user, activeMenu?.ID, farmId]);

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          showHome={false}
          menuRequired={true}
          farmRequired={true}
          houseRequired={true}
        >
          <BreadcrumbItem
            key="form"
            title="Dashboards"
            subtitle=""
            showDivider={false}
            className="cursor-default"
          />
        </Breadcrumb>
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>
            {activeMenu?.Title !== undefined ? (
              activeMenu.Title
            ) : (
              <div className="w-1/3">
                <FieldSkeleton />
              </div>
            )}
          </SectionTitle>
        </div>

        <div className="col-span-full">
          <div className="flex flex-grow flex-col">
            {userDashboards?.length > 0 &&
            farmId !== null &&
            houseId !== null ? (
              <div>
                <Tabs
                  className="mb-4"
                  tabs={userDashboards.map((d) => ({
                    name: d.title,
                    id: d.id,
                    current:
                      selectedDashboardId !== undefined
                        ? selectedDashboardId === d.id
                        : userDashboards?.[0]?.id === d.id,
                  }))}
                  onChange={(id) => setSelectedDashboardId(id)}
                ></Tabs>
                <Dashboard
                  id={selectedDashboardId ?? userDashboards?.[0]?.id}
                />
              </div>
            ) : (
              <ListSkeleton />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

/**
 * A tab content component type.
 * @typedef {Object}  Tab
 * @property  {String}  name
 * @property  {String}  id
 * @property  {Boolean}  current
 */
/**
 * @param {{ tabs: Tab[], onChange: Function }} props
 * @returns
 */
const Tabs = ({ tabs, onChange, ...other }) => {
  return (
    <div {...other}>
      <div className="tablet:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select dashboard
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current)?.name}
          onChange={(ev) => onChange(ev.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden tablet:block">
        <nav
          className="flex"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              onClick={() => onChange(tab.id)}
              className={classNames(
                tab.current
                  ? "bg-primary text-white border-primary bg-gradient-to-r from-primary to-secondary hover:bg-primary-dark"
                  : "bg-transparent text-gray-600 border-gray-300",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group border py-2 px-4 shadow focus:outline-none focus:ring-0"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? "bg-primary" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

import {
  Alert,
  Button,
  ButtonClicked,
  Fieldset,
  Select,
} from "components/core";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import FormField from "components/forms/FormField";
import PageHeader from "components/PageHeader";
import { DATA_STATUS } from "constants.js";
import { AppDataContext } from "context/AppDataProvider";
import {
  IForm,
  IFormField,
  getFormDataStatus,
  IFormValue,
  IFormValueDataSources,
  getPenDataFromFormData,
  IFormData,
  IPenFormValid,
} from "helpers/formUtilities";
import useQuery from "hooks/useQuery";
import { useEffect, useContext, useMemo, useState, useRef } from "react";
import { NotificationContext } from "context/NotificationProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IMenuItem } from "components/Sidebar";
import { DocumentDownloadIcon } from "assets/icons";
import { buildFormValues } from "helpers/formsUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { redirectWithExistingSearchParams } from "helpers/redirectUtilities";

interface ReportFormProps {
  menu: IMenuItem;
}

export default function ReportForm({ ...rest }: ReportFormProps) {
  let { formType, moduleName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedFormId, setSelectedFormId] = useState<String>("");
  const [formValues, setFormValues] = useState<IFormData | undefined>(
    undefined
  );
  const [formValid, setFormValid] = useState<IPenFormValid[]>([]);
  const [isProcessingButtonVisible, setIsProcessingButtonVisible] =
    useState<Boolean>(false);

  const { forms, farms, offline } = useContext(AppDataContext) as any;
  const { addNotification } = useContext(NotificationContext) as any;

  let query = useQuery();
  let farmId = query.get("farmId");

  const abortControllerRef = useRef(undefined) as any;

  const filteredForms = useMemo<IForm[]>(
    () =>
      forms.filter(
        (f: IForm) =>
          f.FormType?.toLowerCase() === formType?.toLowerCase() &&
          f.ModuleName?.toLowerCase() === moduleName?.toLowerCase()
      ),
    [forms, formType, moduleName]
  );

  const farm = useMemo(
    () =>
      farms.find(
        (f: { FarmCode: string }) =>
          f.FarmCode.toLowerCase() === farmId?.toLowerCase()
      ),
    [farms, farmId]
  );

  const selectedForm = useMemo<IForm | undefined>(
    () =>
      filteredForms?.find(
        (f: { FormName: string }) => f.FormName === selectedFormId
      ),
    [filteredForms, selectedFormId]
  );

  const dataStatus = useMemo<Number | undefined>(
    () => getFormDataStatus(formValid),
    [formValid]
  );

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function handleOnSubmit(fileFormat = "pdf") {
    addNotification({
      title: "Downloading report...",
    });

    // Prevent submission when dataStatus is NOT complete or draft
    if (dataStatus !== DATA_STATUS.COMPLETE) {
      addNotification({
        title: "Form is not complete",
        description: "Please fill out all required fields",
        theme: "error",
      });

      return;
    }

    setIsProcessingButtonVisible(true);

    const { signal } = abortControllerRef.current;

    const _reportParams = formValues?.PenValues?.[0].Values?.reduce(
      (acc: { [key: string]: any }, curr) => {
        acc[curr.Ref] = curr.Value;
        return acc;
      },
      {}
    );

    try {
      const response = await fetch("/api/runreport-post", {
        signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ReportName: selectedFormId,
          ReportParams: _reportParams,
          Format: fileFormat,
        }),
      });

      if (response.ok) {
        // if response content type is application/pdf
        if (response.headers.get("content-type")?.startsWith("application/")) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          const fileExtension = fileFormat === "pdf" ? ".pdf" : ".xls";
          link.href = url;
          link.download = `${selectedForm?.FormName}_${
            _reportParams !== undefined
              ? Object.values(_reportParams).join("_")
              : ""
          }${fileExtension}`;
          link.click();
          // window.open(url);
        }

        addNotification({
          title: "Report downloaded",
          theme: "success",
        });
      } else {
        addNotification({
          title: "Invalid request",
          theme: "warning",
          description:
            "The data provided is invalid, please check and try again. If the problem persists, please contact our support team.",
        });
      }
    } catch (error) {
      addNotification({
        title: "Error submitting report",
        theme: "error",
        description:
          "An error occurred while fetching the report. Please try again. If the problem persists, please contact our support team.",
      });
    } finally {
      if (signal.aborted) return;
      setIsProcessingButtonVisible(false);
    }
  }

  async function handleClickCancel(ev: React.MouseEvent<HTMLButtonElement>) {
    ev.preventDefault();

    return navigate(redirectWithExistingSearchParams(location, "/"));
  }

  const handleSetFieldValue = (
    field: IFormField,
    penId: string,
    value: IFormValue["Value"]
  ) => {
    if (!field?.Ref || !penId)
      return new Error("'ref' and 'pen' are required properties.");

    setFormValues((prevState) => {
      const dataSources: IFormValueDataSources = {
        this: prevState ?? {},
      };

      try {
        const newState = buildFormValues(
          field,
          penId,
          value,
          selectedForm,
          dataSources,
          undefined,
          undefined,
          0,
          undefined
        );

        return newState;
      } catch (error) {
        console.error(error);
      }

      return prevState as any;
    });
  };

  function handleSetReportId(id: any) {
    setSelectedFormId(id);
    setFormValues(undefined);
  }

  const handleSetFieldValid = (
    field: IFormField,
    penNumber: string,
    valid: boolean,
    complete: boolean,
    required: boolean
  ) => {
    setFormValid((prevState) => {
      const newFormValid = prevState.filter(
        (fv) =>
          !(
            fv.Ref === field.Ref &&
            (isNullEmptyOrWhitespace(field.QuestionGroup) ||
              fv.QuestionGroup === field.QuestionGroup) &&
            fv.Pen.toString() === penNumber.toString()
          )
      );
      newFormValid.push({
        Ref: field.Ref,
        QuestionGroup: field.QuestionGroup,
        Pen: penNumber,
        Valid: valid,
        Complete: complete,
        Required: required,
      });

      return newFormValid;
    });
  };

  return (
    <div className="flex-grow overflow-x-hidden">
      <div className="relative z-20">
        {/* <Breadcrumb showHome={false} menuRequired={false} farmRequired={true} /> */}
        <PageHeader className="py-6 px-4 sm:px-6 lg:px-8">
          <div className="text-xl">Reports</div>
        </PageHeader>
      </div>
      <main className="flex flex-col flex-grow">
        <div className="flex flex-col flex-grow w-full max-w-7xl mx-auto py-6 tablet:px-6 desktop:px-8">
          {offline ? (
            <Alert theme="warning" className="mb-6">
              You are offline. Please connect to the internet to download
              reports.
            </Alert>
          ) : filteredForms.length > 0 ? (
            <>
              <div className="flex flex-col flex-grow">
                <Fieldset title="Reports" text={undefined} content={undefined}>
                  <div className="space-y-4">
                    <Select
                      label="Report"
                      listOptions={filteredForms.map(
                        (form: { FormTitle: string; FormName: string }) => ({
                          Id: form.FormName,
                          Text: form.FormTitle,
                          Value: form.FormName,
                        })
                      )}
                      id="report"
                      hint={undefined}
                      value={selectedFormId}
                      setValue={handleSetReportId}
                      labelPosition="inset"
                    />
                    {selectedForm &&
                      selectedForm.FormFields.map((ff) => (
                        <FormField
                          key={ff.Ref}
                          {...{
                            field: ff,
                            penNumber: 1,
                            farm,
                            setFieldValue: handleSetFieldValue,
                            setFieldValid: handleSetFieldValid,
                            formValues: getPenDataFromFormData("1", formValues),
                          }}
                        />
                      ))}
                  </div>
                </Fieldset>
              </div>
              <div className="flex space-x-4 mt-4 px-4 justify-end tablet:px-0">
                {isProcessingButtonVisible ? (
                  <ButtonClicked />
                ) : (
                  <>
                    <Button onClick={handleClickCancel}>Cancel</Button>
                    <Button
                      isFullWidth={undefined}
                      theme="primary"
                      label={undefined}
                      onClick={undefined}
                      disabled={undefined}
                      icon={
                        <DocumentDownloadIcon className="h-5 w-5 mr-1 text-primary-lighter" />
                      }
                      // icon={undefined}
                      iconPosition="left"
                      optionsHeading={undefined}
                      optionProps={undefined}
                      showOptions={undefined}
                      onShowOptions={undefined}
                      onHideOptions={undefined}
                      showStats={undefined}
                      showSearch={undefined}
                      optionsFilteredByMeta={undefined}
                      type="submit"
                      options={[
                        {
                          id: "pdf",
                          text: "PDF",
                          onClick: () => handleOnSubmit("pdf"),
                        },
                        {
                          id: "excel",
                          text: "Excel",
                          onClick: () => handleOnSubmit("excel"),
                        },
                      ]}
                    >
                      Download
                    </Button>
                  </>
                )}
              </div>
            </>
          ) : (
            <FieldsetSkeleton />
          )}
        </div>
      </main>
    </div>
  );
}

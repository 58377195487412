import React from "react";
import {
  BarChart,
  LineChart,
  TableChart,
  MetricChart,
  TrendChart,
  ScatterChart,
} from "components/core";

const chartComponents = {
  bar: BarChart,
  line: LineChart,
  table: TableChart,
  metric: MetricChart,
  trend: TrendChart,
  scatter: ScatterChart
};

export default function DynamicChart({ chart, data, standardData, className = "" }) {
  const ChartComponent = chartComponents[chart.type] ?? "div";

  // let keys = chart.keys;
  // if (chart.type === CHART_TYPE.BAR || chart.type === CHART_TYPE.LINE) {
  //   // Always remove first key as it used for X-Axis
  //   keys = chart.keys.filter((k, i) => {
  //     return i > 0;
  //   });
  // } else if (chart.type === CHART_TYPE.METRIC) {}

  return (
    <ChartComponent {...chart} key={chart.id} data={data} standardData={standardData} keys={chart.keys} />
  );
}

import { isNullEmptyOrWhitespace } from "./stringUtilities";

/**
 * Redirect to list view
 * @param {any} location        Location object
 * @param {String} moduleName   Module name
 * @param {String} formType     Form type
 * @param {Boolean} appendQueryString Append query string. Defaults to true
 * @returns {{ pathname: string, search: string }}              An object to be passed to history.push
 */
export function redirectToListView(
  location,
  moduleName,
  formType,
  appendQueryString = true
) {
  // Build URL parts
  // Ordering is important here
  const urlParts = {};
  urlParts.moduleName = moduleName.toLowerCase();
  urlParts.formType = formType.toLowerCase();

  const newUrl = `/${Object.values(urlParts).filter(Boolean).join("/")}`;

  if (appendQueryString) {
    return redirectWithExistingSearchParams(location, newUrl);
  }

  return { pathname: newUrl };
}

/**
 *
 * @param {any} location       Location object
 * @param {string} moduleName  Module name
 * @param {string} formType
 * @param {string} formId
 * @param {string|undefined} parentId
 * @param {string|undefined} parentFormId
 * @param {string|number|undefined} recordId
 * @param {string|undefined} view
 * @param {{farmId: string|undefined, houseId: string|undefined}} urlParams
 * @returns
 */
export function redirectToFormView(
  location,
  moduleName,
  formType,
  formId,
  parentId = undefined,
  parentFormId = undefined,
  recordId = undefined,
  view = undefined,
  urlParams = {
    farmId: undefined,
    houseId: undefined,
  }
) {
  // Build URL parts
  // Ordering is important here
  const urlParts = {};
  urlParts.moduleName = moduleName?.toLowerCase();
  urlParts.formType = formType?.toLowerCase();
  urlParts.formId = formId?.toLowerCase();
  urlParts.view =
    view !== undefined
      ? view?.toLowerCase()
      : !isNullEmptyOrWhitespace(recordId)
      ? "edit"
      : "new";
  urlParts.id = recordId;

  if (!isNullEmptyOrWhitespace(parentId)) {
    urlParts.parentFormId = parentFormId?.toString();
    urlParts.parentId = parentId?.toString();

    if (isNullEmptyOrWhitespace(urlParts.parentFormId)) {
      throw new Error("When parentId is set, parentFormId must NOT be null");
    }
  }

  // Build search params
  const searchParams = new URLSearchParams(location.search.substring(1));
  if (urlParams.farmId !== undefined)
    searchParams.set("farmId", urlParams.farmId.toLowerCase());
  if (urlParams.houseId !== undefined)
    searchParams.set("houseId", urlParams.houseId.toLowerCase());

  return {
    pathname: `/${Object.values(urlParts).filter(Boolean).join("/")}`,
    search: "?" + searchParams.toString(),
  };
}

/**
 *
 * @param {any} currentLocation
 * @param {string} newURL
 * @returns
 */
export function redirectWithExistingSearchParams(currentLocation, newURL) {
  const url = new URL(newURL, window.location.href);

  const _searchParams = new URLSearchParams(
    currentLocation.search.substring(1)
  );
  for (const [key, value] of url.searchParams) {
    _searchParams.set(key, value);
  }

  return {
    pathname: url.pathname,
    search: "?" + _searchParams.toString(),
  };
}

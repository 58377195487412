import {
  useContext,
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
} from "react";
import { AppDataContext } from "context/AppDataProvider";
import { NotificationContext } from "context/NotificationProvider";
import useQuery from "hooks/useQuery";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";
import { Alert, Button } from "components/core";
import DescriptionList from "components/core/Lists/DescriptionList";
import DescriptionListSkeleton from "components/core/Lists/DescriptionListSkeleton";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import {
  getPWAIDfromFormValuesRecord,
  getReadonlyFormValueByFieldType,
} from "helpers/formsUtilities";
import {
  dateToString,
  isDotNetDateString,
  isSQLDateString,
  isUKDateString,
  localDateToSQL,
  sqlDateObjectFromServerTZ,
} from "helpers/dateUtilities";
import { v4 as uuid } from "uuid";
import { redirectToFormView } from "helpers/redirectUtilities";
import { ModalContext } from "context/ModalProvider";
import { BadgeSeverity } from "components/forms/BadgeSeverity";
import { deepClone } from "helpers/dataUtilities";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ReportPrintPDF, ReportPrintMode } from "./ReportPrintPDF";
import { Link } from "react-router-dom";
import Card, { CardTitle } from "components/Card";

const DEFAULT_UNDEFINED = "null";

export default function AuditReportPage(props) {
  const { id, formType, moduleName, formId } = useParams();
  const query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const {
    fetchFormValues,
    farms,
    forms,
    pageTitle,
    setPageTitle,
    user,
  } = useContext(AppDataContext);

  const { addNotification } = useContext(NotificationContext);

  const [completedDate, setCompletedDate] = useState(undefined);
  const [auditData, setAuditData] = useState({});
  const [nonconformanceData, setNonconformanceData] = useState(undefined);
  const [printMode, setPrintMode] = useState(ReportPrintMode.AuditOnlyTable);
  const [isPrinting, setIsPrinting] = useState(false);

  const farm = useMemo(() => {
    if (farmId === null) {
      return undefined;
    }

    return farms.find((f) => f.FarmCode.toLowerCase() === farmId.toLowerCase());
  }, [farms, farmId]);

  const auditForm = useMemo(() => {
    if (formId === undefined) {
      return undefined;
    }

    const form = forms.find(
      (f) =>
        f.FormName.toLowerCase() === formId.toLowerCase() &&
        f.FormType?.toLowerCase() === formType?.toLowerCase() &&
        f.ModuleName.toLowerCase() === moduleName?.toLowerCase()
    );
    if (form === undefined) return undefined;

    const formClone = deepClone(form);

    // Filter form fields by FarmType
    if (farm?.FarmType) {
      formClone.FormFields = formClone.FormFields?.filter(
        (ff) =>
          isNullEmptyOrWhitespace(ff.FarmType) ||
          ff.FarmType?.split(",").some(
            (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
          )
      );
    }

    // Filter form fields by FarmGroup
    if (farm?.FarmGroup) {
      formClone.FormFields = formClone.FormFields?.filter(
        (ff) =>
          isNullEmptyOrWhitespace(ff.FarmGroup) ||
          ff.FarmGroup?.split(",").some(
            (fg) => fg.toLowerCase() === farm.FarmGroup.toLowerCase()
          )
      );
    }

    formClone.FormFields.sort((a, b) => {
      // Sort by position
      const positionDiff = a.Position - b.Position;
      if (positionDiff) {
        return positionDiff;
      }

      // Name
      return a.Name < b.Name ? -1 : 1;
    });

    return formClone;
  }, [farm?.FarmType, farm?.FarmGroup, formId, formType, forms, moduleName]);

  const nonConformanceForm = useMemo(() => {
    if (formId === undefined) {
      return undefined;
    }

    const form = forms.find(
      (f) =>
        f.FormName.toLowerCase() === `ncn_${formId.toLowerCase()}` &&
        f.FormType?.toLowerCase() === formType?.toLowerCase() &&
        f.ModuleName.toLowerCase() === moduleName?.toLowerCase()
    );

    if (form === undefined) return undefined;

    const formClone = deepClone(form);

    // Filter form fields by FarmType
    if (farm?.FarmType) {
      formClone.FormFields = formClone.FormFields?.filter(
        (ff) =>
          isNullEmptyOrWhitespace(ff.FarmType) ||
          ff.FarmType?.split(",").some(
            (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
          )
      );
    }

    formClone.FormFields.sort((a, b) => {
      // Sort by position
      const positionDiff = a.Position - b.Position;
      if (positionDiff) {
        return positionDiff;
      }

      // Name
      return a.Name < b.Name ? -1 : 1;
    });

    return formClone;
  }, [farm?.FarmType, formId, formType, forms, moduleName]);

  const handlePrint = (printMode) => {
    setPrintMode(printMode);
    setIsPrinting(true);
  };

  //#region Callbacks

  const buildChildOutput = (child, _primaryQuestionImages, datasource) => {
    const _readonlyValue = getReadonlyFormValueByFieldType(
      child.field,
      child.data
    );
    const _fieldType = child.field.FieldType?.toLowerCase();
    const isHidden =
      typeof child.field.Display === "string" &&
      child.field.Display.toLowerCase() === "h"
        ? true
        : false;

    if (isHidden) {
      return {
        id: datasource === "audit" ? `cq_${child.field.Name}` : child.field.Ref, //
        title: child.field.Name,
        value: _readonlyValue,
        type: "hidden",
        datasource,
      };
    }

    if (_fieldType === "up") {
      // Add image to print array
      for (const imgUrl of _readonlyValue) {
        _primaryQuestionImages.push({
          id: uuid(),
          url: imgUrl,
        });
      }

      return {
        id: datasource === "audit" ? `cq_${child.field.Name}` : child.field.Ref, //
        title: child.field.Name,
        value: _readonlyValue,
        type: "images",
        datasource,
      };
    }

    return {
      id: datasource === "audit" ? `cq_${child.field.Name}` : child.field.Ref, //
      title: child.field.Name,
      value: _readonlyValue,
      type: "text",
      datasource,
    };
  };

  const generateNonConformantTableData = useMemo(() => {
    if (auditForm?.FormFields === undefined) {
      return {
        questionStatusLookup: [],
        reportData: [],
        reportSummaryData: [],
      };
    }
    const reportData = [];
    const _printImageData = [];

    // Build primary question lookup(s)
    const _primaryQuestionFieldLookup = [];
    const _childQuestionFieldLookup = [];
    const _nonconformanceFieldLookup = [];

    // Audit form fields
    for (const field of auditForm.FormFields) {
      if (
        !isNullEmptyOrWhitespace(field.QuestionGroup) &&
        field.QuestionGroup === field.Ref
      ) {
        _primaryQuestionFieldLookup[field.Ref.toLowerCase()] = field;
      } else {
        const _refKey = field.Ref.toLowerCase();
        const _questionGroupKey =
          field.QuestionGroup?.toLowerCase() ?? DEFAULT_UNDEFINED;

        if (!_childQuestionFieldLookup[_questionGroupKey]) {
          _childQuestionFieldLookup[_questionGroupKey] = [];
        }
        _childQuestionFieldLookup[_questionGroupKey][_refKey] = field;
      }
    }

    // Non-conformance form fields
    for (const field of nonConformanceForm?.FormFields) {
      const _refKey = field.Ref.toLowerCase();
      for (const primaryQuestionRef of Object.keys(
        _primaryQuestionFieldLookup
      )) {
        const _questionGroupKey = primaryQuestionRef;

        if (!_nonconformanceFieldLookup[_questionGroupKey]) {
          _nonconformanceFieldLookup[_questionGroupKey] = [];
        }
        _nonconformanceFieldLookup[_questionGroupKey][_refKey] = field;
      }
    }

    //Build set of non-conformant primary question refs
    const _nonConformancePrimaryQuestionLookup = new Map(); //Non-conformant primary question field and data for easy lookup
    const _childQuestionLookup = []; //Child field and data for easy lookup
    const _nonconformanceQuestionLookup = []; //Non-Conformance field and data for easy lookup
    // const _childQuestionLookup = new Map(); //Child field and data for easy lookup
    const questionStatusLookup = new Map();

    const auditDataPenValues = auditData?.PenValues ?? [];
    for (const pen of auditDataPenValues) {
      for (const pv of pen.Values) {
        const _primaryQuestionField =
          _primaryQuestionFieldLookup[pv.Ref?.toLowerCase()];

        if (_primaryQuestionField) {
          //Primary question
          const listOption = _primaryQuestionField.ListOptions?.find(
            (lo) => lo.Value === pv.Value
          );

          //Build list of value:counter array
          if (listOption !== undefined) {
            const _existingEntry =
              questionStatusLookup.get(listOption.Text) ?? 0;

            questionStatusLookup.set(listOption.Text, {
              id: _existingEntry.id ?? uuid(),
              text: _existingEntry.text ?? listOption.Text,
              severity: _existingEntry.severity ?? listOption.SeverityColour,
              value: (_existingEntry.value ?? 0) + 1,
            });
          }

          _nonConformancePrimaryQuestionLookup.set(pv.Ref.toLowerCase(), {
            field: _primaryQuestionField,
            data: pv,
          });
        } else {
          //Child

          const _refKey = pv.Ref?.toLowerCase();
          const _questionGroupKey =
            pv.QuestionGroup?.toLowerCase() ?? DEFAULT_UNDEFINED;

          const _childQuestionField =
            _childQuestionFieldLookup[_questionGroupKey]?.[_refKey];

          if (!_childQuestionField) continue;

          if (!_childQuestionLookup[_questionGroupKey]) {
            _childQuestionLookup[_questionGroupKey] = [];
          }

          // Format values such as Dates and Numbers
          // Convert dates
          if (
            isSQLDateString(pv.Value) ||
            isDotNetDateString(pv.Value) ||
            isUKDateString(pv.Value)
          ) {
            const convertedValue = sqlDateObjectFromServerTZ(pv.Value);
            pv.Value = localDateToSQL(convertedValue.normalised, {
              includeOffset: false,
            });
          }

          _childQuestionLookup[_questionGroupKey].push({
            field: _childQuestionField,
            data: pv,
          });
        }
      }
    }

    // Non-conformance questions
    const nonConformanceDataPenValues = nonconformanceData?.PenValues ?? [];
    for (const pen of nonConformanceDataPenValues) {
      for (const pv of pen.Values) {
        const _refKey = pv.Ref?.toLowerCase();
        const _questionGroupKey =
          pv.QuestionGroup?.toLowerCase() ?? DEFAULT_UNDEFINED;

        const _nonconformanceQuestionField =
          _nonconformanceFieldLookup[_questionGroupKey]?.[_refKey];

        if (!_nonconformanceQuestionField) continue;

        if (!_nonconformanceQuestionLookup[_questionGroupKey]) {
          _nonconformanceQuestionLookup[_questionGroupKey] = [];
        }

        // Format values such as Dates and Numbers
        // Convert dates
        if (
          isSQLDateString(pv.Value) ||
          isDotNetDateString(pv.Value) ||
          isUKDateString(pv.Value)
        ) {
          const convertedValue = sqlDateObjectFromServerTZ(pv.Value);
          pv.Value = localDateToSQL(convertedValue.normalised, {
            includeOffset: false,
          });
        }

        _nonconformanceQuestionLookup[_questionGroupKey].push({
          field: _nonconformanceQuestionField,
          data: pv,
        });
      }
    }

    const primaryQuestions =
      _nonConformancePrimaryQuestionLookup.values() ?? [];
    for (const primaryQuestion of primaryQuestions) {
      const _questionGroupKey =
        primaryQuestion.field.Ref?.toLowerCase() ?? DEFAULT_UNDEFINED;

      const isNcnPrimaryQuestion =
        primaryQuestion.field.ListOptions?.some((option) =>
          option.hasOwnProperty("IsNcn")
        ) ?? false;

      const hasCompletedNonConformance =
        _nonconformanceQuestionLookup[_questionGroupKey]?.length > 0;

      const _primaryQuestionImages = [];

      const listOptions = primaryQuestion.field.ListOptions;
      const selectedListOption = listOptions?.find(
        (lo) => lo.Value === primaryQuestion.data.Value
      );
      const hasNonConformance =
        isNcnPrimaryQuestion && selectedListOption !== undefined
          ? selectedListOption.IsNcn?.toString().toLowerCase() === "true"
          : false;
      const value = !isNullEmptyOrWhitespace(listOptions)
        ? primaryQuestion.data.Text
        : primaryQuestion.data.Value;
      const statusColor = selectedListOption?.SeverityColour;

      reportData.push({
        id: `${primaryQuestion.field.Ref}`,
        title: primaryQuestion.field.Name,
        children: [
          {
            id: "answer",
            title: "Answer",
            value: value,
            statusColor,
            type: !isNullEmptyOrWhitespace(listOptions) ? "status" : "text",
            datasource: "audit",
          },
          primaryQuestion.field.Section && {
            id: "section",
            title: "Section",
            value: primaryQuestion.field.Section,
            type: "text",
            datasource: "audit",
          },
          ...(_childQuestionLookup[_questionGroupKey] ?? []).map((child) => {
            return buildChildOutput(child, _primaryQuestionImages, "audit");
          }),
          ...(_nonconformanceQuestionLookup[_questionGroupKey] ?? []).map(
            (child) => {
              return buildChildOutput(
                child,
                _primaryQuestionImages,
                "nonconformance"
              );
            }
          ),
          hasNonConformance &&
            !hasCompletedNonConformance && {
              id: "alert",
              title: "Non-conformances",
              value: (
                <Alert theme="warning">
                  Non-conformance form has NOT been completed.
                </Alert>
              ),
              type: "alert",
              datasource: "audit",
            },
        ],
        section: primaryQuestion.field.Section?.toLowerCase(),
        hasNonConformance,
      });

      _primaryQuestionImages?.length > 0 &&
        _printImageData.push({
          id: `${primaryQuestion.field.Ref}`,
          title: primaryQuestion.field.Name,
          value: _primaryQuestionImages,
          type: "images",
          datasource: "audit",
          hasNonConformance,
        });
    }

    return {
      questionStatusLookup: Array.from(questionStatusLookup.values()).sort(
        (a, b) => b.severity - a.severity
      ),
      reportData,
      printImages: _printImageData,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auditData?.PenValues,
    auditForm?.FormFields,
    formId,
    id,
    nonconformanceData?.PenValues,
    nonConformanceForm?.FormFields,
  ]);

  //#endregion

  //#region side-effects

  /**
   * Set page title
   */
  useEffect(() => {
    if (auditForm?.FormTitle === undefined) {
      setPageTitle("Report");
      return;
    }

    setPageTitle(`${auditForm.FormTitle} - Report`);
  }, [auditForm?.FormTitle, setPageTitle]);

  /**
   * Fetch form values
   */
  useDeepCompareEffectNoCheck(() => {
    if (!formId || !farmId || !houseId || !formType || !farms.length) {
      // Reset form valid array to prevent bleed when changing farms
      setNonconformanceData(undefined);
      setCompletedDate(undefined);
      setAuditData({});

      return;
    }

    const abortController = new AbortController();

    const { signal } = abortController;

    const _formIds = [{ formId, formType, moduleId: moduleName }];
    _formIds.push({
      formId: `ncn_${formId}`,
      formType,
      moduleId: moduleName,
    });

    // Fetch form values
    fetchFormValues(farmId, houseId, _formIds, signal)
      .then((res) => {
        if (signal.aborted) return;

        // Audit records
        const auditRecords = res[0];
        const currentRecord = auditRecords.find(
          (record) =>
            record.ID?.toString() === id ||
            getPWAIDfromFormValuesRecord(record) === id
        );
        setAuditData(currentRecord);

        // Non-Conformance record
        const nonconformanceRecords = res[1];
        const currentNonconformanceRecord = nonconformanceRecords.find(
          (record) =>
            record.ParentPWAID === getPWAIDfromFormValuesRecord(currentRecord)
        );

        setNonconformanceData(currentNonconformanceRecord ?? null);

        const _completedDate = currentRecord._DateApplies.normalised;
        setCompletedDate(_completedDate);
      })
      .catch((error) => {
        if (signal.aborted) return;

        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while fetching the form data.",
        });

        console.error(error.message);
      });

    return () => {
      abortController.abort();
    };
  }, [formId, formType, farmId, houseId, id, farms]);

  //#endregion

  return (
    <main className="flex flex-col flex-grow">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          key="breadcrumb"
          className="print:hidden"
          showHome={false}
          farmRequired={true}
          houseRequired={true}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <div className="mb-2 flex flex-row items-center">
            <div className="text-lg text-gray-600 uppercase flex-grow font-medium">
              {pageTitle}
            </div>
            {generateNonConformantTableData.reportData.length > 0 && (
              <>
                <Button
                  // onClick={handlePrint}
                  theme="primary"
                  options={[
                    {
                      id: "print-audit-table",
                      text: "Print Audit",
                      onClick: () =>
                        handlePrint(ReportPrintMode.AuditOnlyTable),
                    },
                    {
                      id: "print-nonconformances-table",
                      text: "Print Non-Conformances",
                      onClick: () =>
                        handlePrint(ReportPrintMode.NonConformancesOnlyTable),
                    },
                    {
                      id: "print-audit-table-w-images",
                      text: "Print Audit (w/ images)",
                      onClick: () =>
                        handlePrint(
                          ReportPrintMode.AuditOnlyTableWithLargeImages
                        ),
                    },
                    {
                      id: "print-nonconformances-table-w-images",
                      text: "Print Non-Conformances (w/ images)",
                      onClick: () =>
                        handlePrint(
                          ReportPrintMode.NonConformancesOnlyTableWithLargeImages
                        ),
                    },
                  ]}
                >
                  Print
                </Button>
                {isPrinting && (
                  <PDFDownloadLink
                    document={
                      <ReportPrintPDF
                        farm={farm}
                        auditForm={auditForm}
                        completedDate={completedDate}
                        auditor={auditData?.CreatedBy ?? user?.FullName}
                        nonconformanceCounts={
                          generateNonConformantTableData.questionStatusLookup
                        }
                        reportData={generateNonConformantTableData.reportData}
                        printImages={generateNonConformantTableData.printImages}
                        printMode={printMode}
                      />
                    }
                    fileName="reportprintpdf.pdf"
                    style={{ display: "none" }}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <OpenNewWindow
                          url={url}
                          loading={loading}
                          callback={() => setIsPrinting(false)}
                        />
                      )
                    }
                  </PDFDownloadLink>
                )}
              </>
            )}
          </div>
          <ReportDetail
            farm={farm}
            form={auditForm}
            ncnForm={nonConformanceForm}
            auditor={auditData?.CreatedBy ?? user?.FullName}
            completedDate={completedDate}
            data={generateNonConformantTableData.reportData}
            pwaId={getPWAIDfromFormValuesRecord(auditData)}
            questionStatusLookup={
              generateNonConformantTableData.questionStatusLookup
            }
            auditStatus={auditData?.AuditStatus}
          />
        </div>
      </div>
    </main>
  );
}

const ReportDetail = ({
  farm,
  form,
  ncnForm,
  auditor,
  completedDate,
  data,
  pwaId,
  questionStatusLookup,
}) => {
  const location = useLocation();
  const { id, moduleName, formType } = useParams();

  const { setShowModal } = useContext(ModalContext);

  const onClickImage = (url) => {
    setShowModal(true, <img src={url} alt={url} />);
  };

  const summaryData = data.filter((row) => row.section === "summary");

  return (
    <div className="flex flex-col flex-grow w-full">
      <div className="grid tablet:grid-cols-12 gap-4">
        <div className="col-span-8 flex">
          <Card className="flex-grow">
            <CardTitle>Details</CardTitle>
            {farm?.FarmName !== undefined &&
            form?.FormName !== undefined &&
            completedDate !== undefined ? (
              <DescriptionList
                className="divide-y divide-gray-100 -mx-4"
                items={[
                  {
                    id: "farmcode",
                    title: "Farm",
                    value: `${farm?.FarmName} (${farm?.FarmCode})`,
                  },
                  { id: "form", title: "Form", value: form?.FormName },
                  {
                    id: "date",
                    title: "Date",
                    value: dateToString(completedDate, {
                      includeTime: false,
                    }),
                  },
                  {
                    id: "auditor",
                    title: "Auditor",
                    value: auditor,
                  },
                  // {
                  //   id: "flockdate",
                  //   title: "Flock Date",
                  //   value: dateToString(placement?._CropDate?.normalised, {
                  //     includeTime: false,
                  //   }),
                  // },
                  // {
                  //   id: "auditStatus",
                  //   title: "Audit Status",
                  //   value: auditStatusToText(auditStatus),
                  // },
                ]}
              />
            ) : (
              <DescriptionListSkeleton />
            )}
          </Card>
        </div>
        <div className="col-span-4 flex">
          <Card className="flex-grow">
            <CardTitle>Summary</CardTitle>
            {questionStatusLookup?.length > 0 ? (
              <DescriptionList
                className="divide-y divide-gray-100 -mx-4"
                items={questionStatusLookup.map((status) => ({
                  id: status.id,
                  title: status.text,
                  value: (
                    <BadgeSeverity severity={status.severity}>
                      {status.value}
                    </BadgeSeverity>
                  ),
                }))}
              />
            ) : (
              <DescriptionListSkeleton />
            )}
          </Card>
        </div>

        <div className="col-span-full">
          {summaryData.length > 0 &&
            (form?.FormTitle !== undefined ? (
              <Card>
                <CardTitle>{form?.FormTitle ?? "Form"} Summary</CardTitle>
                <DescriptionList
                  className="divide-y divide-gray-100 page-break -mx-4"
                  items={summaryData.map((sd) => {
                    const answer = sd.children.find((c) => c.id === "answer");

                    if (answer.type === "status") {
                      return {
                        id: sd.id,
                        title: sd.title,
                        value: !isNullEmptyOrWhitespace(answer) ? (
                          <BadgeSeverity severity={answer.statusColor}>
                            {answer.value}
                          </BadgeSeverity>
                        ) : null,
                      };
                    }

                    return {
                      id: sd.id,
                      title: sd.title,
                      value: !isNullEmptyOrWhitespace(answer)
                        ? answer.value
                        : "-",
                    };
                  })}
                />
              </Card>
            ) : (
              <Card>
                <DescriptionListSkeleton />
              </Card>
            ))}
        </div>

        <div className="col-span-full">
          {data?.length > 0 ? (
            <div>
              <h3 className="text-lg text-medium text-gray-600 mb-2">
                Questions
              </h3>
              <div className="space-y-4">
                {data.map((row) => (
                  <Card>
                    <CardTitle>
                      {location !== undefined &&
                      ncnForm?.FormName !== undefined &&
                      pwaId !== undefined &&
                      form?.FormName !== undefined &&
                      id !== undefined ? (
                        <Link
                          to={redirectToFormView(
                            location,
                            moduleName,
                            formType,
                            ncnForm?.FormName,
                            pwaId,
                            form?.FormName,
                            id
                          )}
                        >
                          {row.title}
                        </Link>
                      ) : (
                        <div className="animate-pulse text-sm font-medium text-gray-500 title">
                          <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
                        </div>
                      )}
                    </CardTitle>
                    <DescriptionList
                      className="divide-y divide-gray-100 -mx-4"
                      items={row.children}
                      onClickImage={(url) => onClickImage(url)}
                    />
                  </Card>
                ))}
              </div>
            </div>
          ) : (
            <DescriptionListSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};

const OpenNewWindow = ({ loading, url, callback }) => {
  useLayoutEffect(() => {
    if (!loading && url !== null) {
      window.open(url, "_blank");

      if (callback !== undefined) {
        callback();
      }
    }
  }, [loading, url, callback]);

  return null;
};


import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Popover, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import ReactDOM from "react-dom";
import classNames from "classnames";

function FlyoutMenu({
  buttonContent,
  buttonClassNames,
  buttonAs = "button",
  children,
  placement,
  ...other
}) {
  let [referenceElement, setReferenceElement] = useState(undefined);
  let [popperElement, setPopperElement] = useState(undefined);
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
  });

  return (
    <Popover {...other}>
      {({ open }) => (
        <>
          <Popover.Button
            key="popoverButton"
            as={buttonAs}
            ref={setReferenceElement}
            className={classNames("flex flex-1", buttonClassNames)}
          >
            {!!buttonContent && buttonContent({ open })}
          </Popover.Button>
          {ReactDOM.createPortal(
            <Transition
              key="popoverTransition"
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                ref={(ref) => setPopperElement(ref)}
                style={styles.popper}
                className="mt-3 px-2 w-screen max-w-xs sm:px-0"
                {...attributes.popper}
                static
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    {children}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>,
            document.querySelector("#root")
          )}
        </>
      )}
    </Popover>
  );
}

FlyoutMenu.propTypes = {
  buttonContent: PropTypes.func,
  buttonAs: PropTypes.string,
  children: PropTypes.node,
  placement: PropTypes.oneOf([
    "top-end",
    "top-start",
    "top",
    "right-end",
    "right-start",
    "right",
    "bottom-end",
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    undefined
  ]),
};

export { FlyoutMenu };

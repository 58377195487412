import {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react";
import Breadcrumb from "components/Breadcrumb";
import { CHART_TYPE, DASHBOARD_DATASOURCES } from "constants.js";
import { Button, Input, MultiSelect, Select } from "components/core";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { dateToISO } from "helpers/dateUtilities";
import { AppDataContext, useMenus } from "context/AppDataProvider";
import { NotificationContext } from "context/NotificationProvider";
import Widget from "components/Dashboard/Builder/Widget";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  extractMetricsFromJsonObjs,
  formatChartAttrs,
  formatChartData,
  getUserDashboardById,
} from "helpers/dashboardUtilities";
import { redirectToListView } from "helpers/redirectUtilities";
import useDeepCompareEffect from "use-deep-compare-effect";
import AddChart from "components/Dashboard/Builder/AddChart";
import { ReactSortable } from "react-sortablejs";
import FieldSkeleton from "components/core/Forms/FieldSkeleton";
import SectionTitle from "components/SectionTitle";

export default function Builder() {
  const { id: dashboardId, moduleName, formType } = useParams();

  const abortControllerRef = useRef(undefined);

  const navigate = useNavigate();
  const location = useLocation();
  const { menus } = useMenus();

  const { addNotification } = useContext(NotificationContext);
  const { farms, dashboards } = useContext(AppDataContext);

  //#region State

  const [reportsData, setReportsData] = useState([]);
  const [standardsData, setStandardsData] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [standardMetrics, setStandardMetrics] = useState([]);
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [chartsData, setChartsData] = useState([]);
  const [chartTypes, setChartTypes] = useState([]);
  const [code, setCode] = useState("");
  const [farmGroups, setFarmGroups] = useState([]);

  // Form properties
  const [selectedFarmGroups, setSelectedFarmGroups] = useState("");
  const [selectedDataSources, setSelectedDataSources] = useState("");
  const [selectedMenuId, setSelectedMenuId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("New dashboard");
  const [selectedFarmId, setSelectedFarmId] = useState(undefined);
  const [selectedHouseId, setSelectedHouseId] = useState(undefined);

  //#endregion

  //#region Memos

  const menuListOptions = useMemo(() => {
    const result = [];
    for (const parentMenu of menus) {
      for (const menu of parentMenu.Children) {
        result.push({
          Id: menu.ID,
          Text: `${parentMenu.Title} > ${menu.Title}`,
          Value: menu.ID,
        });
      }
    }

    return result;
  }, [menus]);

  const farm = useMemo(
    () => farms.find((f) => f.FarmCode.toLowerCase() === selectedFarmId?.toLowerCase()),
    [farms, selectedFarmId]
  );

  //#region Callbacks

  const fetchCharts = useCallback(async () => {
    // Charts
    const _dashboard = getUserDashboardById(dashboards, dashboardId);
    if (_dashboard === undefined) {
      setSelectedDataSources("");
      setSelectedFarmGroups("");
      setSelectedMenuId("");
      setSelectedTitle("New dashboard");
      return;
    }
    const _charts = _dashboard?.charts ?? [];

    // Build array of charts and chart data
    for (const chart of _charts) {
      // Add chart
      addChart(chart);
    }

    // Set form defaults
    setSelectedDataSources(_dashboard.dataSources);
    setSelectedFarmGroups(_dashboard.farmGroups);
    setSelectedMenuId(_dashboard.menuId);
    setSelectedTitle(_dashboard.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboards, dashboardId]);

  const fetchFarmGroups = useCallback(async () => {
    const { signal } = abortControllerRef.current;
    /**
     * Fetch farm groups
     */
    try {
      const response = await fetch(`/api/farmgroups-get`, {
        signal,
        method: "GET",
      });
      if (response.ok) {
        const responseData = await response.json();

        setFarmGroups(responseData);
        return responseData;
      } else {
        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while fetching farm groups.",
        });
      }
    } catch (err) {
      if (signal.aborted) return;
      console.error(err);

      addNotification({
        title: "Error",
        theme: "error",
        description: "An error occurred while fetching farm groups.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchChartTypes = () => {
    setChartTypes(
      Object.entries(CHART_TYPE).map(([key, value]) => {
        return {
          id: key.toLowerCase(),
          title: value,
        };
      })
    );
  };

  const addChart = (newChart) => {
    if (newChart.type === undefined) {
      // Avoid adding empty charts
      return;
    }

    // Add new chart to the list
    setSelectedCharts((prevState) => {
      const newState = [...prevState];
      newState.unshift(newChart);

      // Sort by position, desc
      newState.sort((a, b) => a.position - b.position);

      return newState;
    });

    addChartData(newChart, reportsData, standardsData);
  };

  const addChartData = (chart, reports, standards) => {
    if (chart.type === undefined) {
      // Avoid adding empty charts
      return;
    }

    // Build data
    setChartsData((prevState) => {
      const newState = prevState.filter((cd) => cd.id !== chart.id);
      const _chartData = formatChartData(chart, reports, standards);

      newState.push({
        id: chart.id,
        data: _chartData.data,
        standardData: _chartData.standardData,
      });

      return newState;
    });
  };

  //#endregion

  //#region Side-effects

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  /**
   * Fetch charts
   */
  useEffect(() => {
    if (dashboardId !== undefined) {
      fetchCharts();
    }
    fetchChartTypes();
    fetchFarmGroups();
  }, [dashboardId, fetchCharts, fetchFarmGroups]);

  /**
   * Fetch data
   */
  useDeepCompareEffect(() => {
    if (
      isNullEmptyOrWhitespace(selectedDataSources) ||
      (selectedDataSources !== undefined &&
        selectedDataSources
          .split(",")
          .some(
            (ds) =>
              ds === DASHBOARD_DATASOURCES.PRODUCTION ||
              ds === DASHBOARD_DATASOURCES.GRADING
          ) &&
        (selectedFarmId === undefined || selectedHouseId === undefined))
    )
      return;

    const fetchData = async () => {
      // Clear previous values
      setReportsData([]);
      setStandardsData([]);
      setMetrics([]);
      setStandardMetrics([]);

      // Fetch dashboard data
      const _dataSources = selectedDataSources.split(",");
      const responseArray = await Promise.all(
        _dataSources.map((dataSource) => fetchData(dataSource))
      );

      let _flattenedReports = [];
      let _flattenedStandards = [];
      for (const response of responseArray) {
        if (response?.d?.data) {
          _flattenedReports = _flattenedReports.concat(response.d.data);
        }
        if (response?.d?.standards) {
          _flattenedStandards = _flattenedStandards.concat(
            response.d.standards
          );
        }
      }

      setReportsData(_flattenedReports);
      setStandardsData(_flattenedStandards);
      setMetrics(extractMetricsFromJsonObjs(_flattenedReports));
      setStandardMetrics(extractMetricsFromJsonObjs(_flattenedStandards));

      // Charts
      // Add data to charts
      for (const chart of selectedCharts) {
        // Add chart data
        addChartData(chart, _flattenedReports, _flattenedStandards);
      }

      /**
       * Fetch data
       */
      async function fetchData(dataSources) {
        const { signal } = abortControllerRef.current;
        // DashboardChartsWebService

        // const dates = getLastXDays(60); // month
        // const fromDate = dateToISO(dates[dates.length - 1].getTime());
        // const toDate = dateToISO(dates[0].getTime());
        let params = {};

        const farm = farms.find(
          (farm) =>
            farm.FarmCode.toLowerCase() === selectedFarmId?.toLowerCase()
        );
        if (farm !== undefined) {
          params.farmId = farm.FarmCode;
        }

        const house = farm?.Houses?.find(
          (h) => h.HouseNumber.toString() === selectedHouseId
        );
        if (house) {
          params.houseId = house.HouseNumber;
        }

        const cropDate = house?.Pens?.find(
          (p) => p.PenNumber.toString() === "1"
        ).Placement?._CropDate?.normalised;
        if (cropDate) {
          const cropDateFormatted = dateToISO(cropDate?.getTime());
          params.cropDate = cropDateFormatted;
        }

        try {
          const paramKeys = Object.keys(params);
          const response = await fetch(
            // `/api/chartdata-get?farmId=${farmId}&toDate=${toDate}&fromDate=${fromDate}`,
            `/api/chartdata-get?dataSource=${dataSources}${
              paramKeys.length
                ? "&" +
                  paramKeys.map((key) => `${key}=${params[key]}`).join("&")
                : ""
            }`,
            {
              signal,
              method: "GET",
            }
          );
          if (response.ok) {
            const responseData = await response.json();

            return responseData;
          } else {
            addNotification({
              title: "Error",
              theme: "error",
              description: "An error occurred while fetching dashboard data.",
            });
          }
        } catch (err) {
          if (signal.aborted) return;
          console.error(err);

          addNotification({
            title: "Error",
            theme: "error",
            description: "An error occurred while fetching dashboard data.",
          });
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFarmId, farms, selectedHouseId, selectedDataSources]);

  /**
   * Generate export code on-the-fly
   */
  useDeepCompareEffect(() => {
    function generateJsonCode() {
      const newCode = JSON.stringify(selectedCharts);
      setCode(newCode);
    }

    generateJsonCode();
  }, [selectedCharts]);

  //#endregion

  //#region handlers

  const handleSaveDashboard = async () => {
    const { signal } = abortControllerRef.current;
    try {
      const response = await fetch(`/api/dashboard-post`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: isNullEmptyOrWhitespace(dashboardId) ? uuid() : dashboardId,
          title: selectedTitle,
          selectedFarmId,
          selectedHouseId,
          farmGroups: selectedFarmGroups,
          menuId: selectedMenuId,
          charts: code,
          dataSources: selectedDataSources,
        }),
      });

      if (response.ok) {
        addNotification({
          title: "Saved",
          theme: "success",
          description: "Your dashboard has been saved.",
        });

        return navigate(redirectToListView(location, moduleName, formType));
      } else {
        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while saving your dashboard.",
        });
      }
    } catch (err) {
      if (signal.aborted) return;
      console.error(err);

      addNotification({
        title: "Error",
        theme: "error",
        description: "An error occurred while saving your dashboard.",
      });
    }
  };

  const handleDeleteDashboard = async () => {
    const { signal } = abortControllerRef.current;
    try {
      await fetch(`/api/dashboard-delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: dashboardId,
        }),
      });

      addNotification({
        title: "Deleted",
        theme: "success",
        description: "Your dashboard has been deleted.",
      });

      return navigate(redirectToListView(location, moduleName, formType));
    } catch (err) {
      if (signal.aborted) return;
      console.error(err);
    }
  };

  const handleChangeTitle = (value) => {
    setSelectedTitle(value);
  };

  const handleClickCancel = () => {
    return navigate(redirectToListView(location, moduleName, formType));
  };

  const handleDeleteChart = async (chartId) => {
    try {
      setSelectedCharts(selectedCharts.filter((sc) => sc.id !== chartId));
      addNotification({
        title: "Deleted",
        theme: null,
        description: "Chart deleted.",
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeChartTitle = (chartId, value) => {
    const newSelectedCharts = [...selectedCharts];
    const chartIndex = newSelectedCharts.findIndex((x) => x.id === chartId);
    const chart = { ...newSelectedCharts[chartIndex] };

    // Update title
    chart.title = value;

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, chart);

    setSelectedCharts(newSelectedCharts);
  };

  const handleChangeAttr = (chartId, name, value) => {
    const newSelectedCharts = [...selectedCharts];
    const chartIndex = newSelectedCharts.findIndex((x) => x.id === chartId);
    const chart = { ...newSelectedCharts[chartIndex] };

    chart.attrs = {
      ...chart.attrs,
      [name]: value,
    };

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, chart);

    setSelectedCharts(newSelectedCharts);
  };

  const handleChangeSetting = (chartId, name, value) => {
    const newSelectedCharts = [...selectedCharts];
    const chartIndex = selectedCharts.findIndex((x) => x.id === chartId);
    const chart = selectedCharts[chartIndex];

    const newChart = {
      ...chart,
      settings: {
        ...chart.settings,
        [name]: value,
      },
    };

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, newChart);

    setSelectedCharts(newSelectedCharts);
  };

  const handleChangeFarmGroups = (values) => {
    setSelectedFarmGroups(values);
  };

  const handleChangeMenu = (values) => {
    setSelectedMenuId(values);
  };

  const handleChangeDataSources = (values) => {
    setSelectedDataSources(values);
    setSelectedFarmId(undefined);
    setSelectedHouseId(undefined);
  };

  const handleChangeFarm = (value) => {
    setSelectedHouseId(undefined);
    setSelectedFarmId(
      !isNullEmptyOrWhitespace(value) ? value : undefined
    );
  };

  const handleChangeHouse = (value) => {
    setSelectedHouseId(
      !isNullEmptyOrWhitespace(value) ? value?.toString() : undefined
    );
  };

  const handleAddChart = (chartType) => {
    if (!chartType) throw new Error("Chart type is required");

    // Setup chart defaults
    const newChart = {
      id: uuid(),
      title: undefined,
      type: chartType,
      keys: [],
      attrs: {},
      settings: {
        colors: ["#df386f", "#01295F", "#437F97", "#849324", "#FFB30F"],
      },
      position: selectedCharts.length,
    };

    addChart(newChart);
  };

  const handleAddMetrics = (chartId, metricIds) => {
    if (!metricIds) throw new Error("Metric ID is required");

    const newSelectedCharts = [...selectedCharts];
    const chartIndex = selectedCharts.findIndex((x) => x.id === chartId);
    const chart = selectedCharts[chartIndex];

    const newChart = {
      ...chart,
      keys: [], // Clear existing keys
    };

    for (const metricId of metricIds) {
      // Get metric from metric ID
      const metric = metrics.find((x) => x.id === metricId);
      if (!metric) throw new Error(`${metricId} metric not found`);
      // Add metric to the list
      newChart.keys.push(metric);
    }

    // Set widget title if not set
    if (!newChart.title) {
      newChart.title = `${newChart.keys[0].title} Chart`;
    }

    // Update chart attrs
    const _chartAttrs = formatChartAttrs(newChart);
    newChart.attrs = _chartAttrs;

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, newChart);

    setSelectedCharts(newSelectedCharts);

    // Add chart data
    addChartData(newChart, reportsData, standardsData);
  };

  const handleAddTooltipMetrics = (chartId, metricIds) => {
    let filteredMetricsIds = (metricIds ?? []).filter(
      (id) => !isNullEmptyOrWhitespace(id)
    );

    const newSelectedCharts = [...selectedCharts];
    const chartIndex = selectedCharts.findIndex((x) => x.id === chartId);
    const chart = selectedCharts[chartIndex];

    const newChart = {
      ...chart,
      tooltipKeys: [], // Clear existing keys
    };

    if (!filteredMetricsIds.length) {
      newChart.tooltipKeys = [];
    } else {
      for (const metricId of filteredMetricsIds) {
        // Get metric from metric ID
        const metric = metrics.find((x) => x.id === metricId);
        if (!metric) throw new Error(`${metricId} metric not found`);
        // Add metric to the list
        newChart.tooltipKeys.push(metric);
      }
    }

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, newChart);

    setSelectedCharts(newSelectedCharts);

    // Add chart data
    addChartData(newChart, reportsData, standardsData);
  };

  const handleAddStandardMetrics = (chartId, metricIds) => {
    if (!metricIds) throw new Error("Metric ID is required");

    const newSelectedCharts = [...selectedCharts];
    const chartIndex = selectedCharts.findIndex((x) => x.id === chartId);
    const chart = selectedCharts[chartIndex];

    const newChart = {
      ...chart,
      standardKeys: [], // Clear existing keys
    };

    for (const metricId of metricIds) {
      // Get metric from metric ID
      const metric = standardMetrics.find((x) => x.id === metricId);
      if (!metric) throw new Error(`${metricId} metric not found`);
      // Add metric to the list
      newChart.standardKeys.push(metric);
    }

    // Insert new chart in same array location
    newSelectedCharts.splice(chartIndex, 1, newChart);

    setSelectedCharts(newSelectedCharts);

    // Add chart data
    addChartData(newChart, reportsData, standardsData);
  };

  const handleAddColors = (chartId, colorIds) => {
    if (!colorIds) throw new Error("Color ID is required");

    handleChangeSetting(chartId, "colors", colorIds);
  };

  function handleChangePosition(values) {
    const actualCharts = values.filter((x) => x.type !== undefined);

    // Set position property for each chart from index
    const _charts = actualCharts.map((chart, index) => {
      return { ...chart, position: index };
    });

    setSelectedCharts(_charts);
  }

  //#endregion

  let parsedCode = [];
  if (!!parsedCode) {
    try {
      parsedCode = JSON.parse(code);
    } catch {
      // Do nothing
    }
  }

  return (
    <main className="flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          key="breadcrumb"
          showHome={false}
          showFarm={false}
          showHouse={false}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>Dashboard Builder</SectionTitle>
        </div>
        <div className="col-span-full">
          <div className="flex flex-grow flex-col">
            <div className="p-4">
              {isNullEmptyOrWhitespace(selectedDataSources) ? (
                <div className="text-center text-warning-500">
                  Select a data source to continue.
                </div>
              ) : selectedDataSources !== undefined &&
                selectedDataSources
                  .split(",")
                  .some(
                    (ds) =>
                      ds === DASHBOARD_DATASOURCES.PRODUCTION ||
                      ds === DASHBOARD_DATASOURCES.GRADING
                  ) &&
                (selectedFarmId === undefined ||
                  selectedHouseId === undefined) ? (
                <div className="text-center text-warning-500">
                  Select a farm &amp; house to continue.
                </div>
              ) : (
                <>
                  {chartTypes.length > 0 ? (
                    <AddChart
                      chartTypes={chartTypes}
                      className="col-span-12 mb-6 h-12"
                      onAddChart={handleAddChart}
                    />
                  ) : (
                    <FieldSkeleton />
                  )}
                  {reportsData?.length ? (
                    selectedCharts?.length ? (
                      <ReactSortable
                        list={selectedCharts}
                        setList={(values) => {
                          handleChangePosition(values);
                        }}
                        className="grid gap-4 grid-cols-12"
                        tag="div"
                        handle=".dragHandle"
                        // onEnd={handleChangePosition}
                      >
                        {selectedCharts.map((chart) => {
                          const chartData = chartsData?.find(
                            (cd) => cd.id === chart.id
                          );
                          return (
                            <Widget
                              key={`widget_${chart.id}`}
                              chart={chart}
                              data={chartData?.data}
                              standardData={chartData?.standardData}
                              onDelete={handleDeleteChart}
                              onChangeTitle={handleChangeChartTitle}
                              onChangeAttr={handleChangeAttr}
                              onChangeSetting={handleChangeSetting}
                              metrics={metrics}
                              standardMetrics={standardMetrics}
                              onAddMetrics={handleAddMetrics}
                              onAddTooltipMetrics={handleAddTooltipMetrics}
                              onAddStandardMetrics={handleAddStandardMetrics}
                              onAddColors={handleAddColors}
                            />
                          );
                        })}
                      </ReactSortable>
                    ) : null
                  ) : (
                    <FieldSkeleton />
                  )}
                </>
              )}
              <div className="space-y-4 mt-4">
                <Input
                  id="title"
                  label="Dashboard Title"
                  type="text"
                  value={selectedTitle}
                  labelPosition="top"
                  setValue={handleChangeTitle}
                  disableCalcTrigger={true}
                />
                <MultiSelect
                  label="Data Source"
                  id="datasource-multiselect"
                  setValue={(value) => handleChangeDataSources(value)}
                  value={selectedDataSources}
                  listOptions={Object.values(DASHBOARD_DATASOURCES).map(
                    (dataSource) => ({
                      Id: dataSource,
                      Text: dataSource,
                      Value: dataSource,
                    })
                  )}
                  disableCalcTrigger={true}
                  sortable={true}
                />
                {selectedDataSources !== undefined &&
                selectedDataSources
                  .split(",")
                  .some(
                    (ds) =>
                      ds === DASHBOARD_DATASOURCES.PRODUCTION ||
                      ds === DASHBOARD_DATASOURCES.GRADING
                  ) ? (
                  <div className="p-4 border border-gray-300 rounded-md space-y-4">
                    <div className="text-sm font-medium">Sample data...</div>
                    <p className="text-xs italic text-warning-500">
                      The following data is used to populate the data metrics
                      and is not saved with the dashboard.
                    </p>

                    <Select
                      label="Farm"
                      id="farm-select"
                      setValue={(value) => handleChangeFarm(value)}
                      value={selectedFarmId}
                      defaultValue={farms?.[0]?.FarmCode?.toString()}
                      listOptions={farms.map((f) => ({
                        Id: f.FarmCode.toString(),
                        Text: f.FarmName,
                        Value: f.FarmCode.toString(),
                      }))}
                      disableCalcTrigger={true}
                    />
                    <Select
                      label="House"
                      id="house-select"
                      setValue={(value) => handleChangeHouse(value)}
                      value={selectedHouseId}
                      defaultValue={farms?.[0]?.Houses?.[0]?.HouseNumber?.toString()}
                      listOptions={farm?.Houses?.map((f) => ({
                        Id: f.HouseNumber.toString(),
                        Text: f.HouseLabel,
                        Value: f.HouseNumber.toString(),
                      }))}
                      disableCalcTrigger={true}
                    />
                  </div>
                ) : null}
                <div className="p-4 border border-gray-300 rounded-md space-y-4">
                  <div className="text-sm font-medium">Filter by...</div>
                  <p className="text-xs italic text-gray-500">
                    The following selections will dictate where the dashboard
                    will appear.
                  </p>

                  {farmGroups.length > 0 && (
                    <MultiSelect
                      label="Farm group(s)"
                      id="farmgroups-multiselect"
                      setValue={(ev) => handleChangeFarmGroups(ev)}
                      value={selectedFarmGroups}
                      listOptions={farmGroups.map((fg) => ({
                        Id: fg,
                        Text: fg,
                        Value: fg,
                      }))}
                      disableCalcTrigger={true}
                    />
                  )}
                  {menus?.length > 0 && (
                    <Select
                      label="Page(s)"
                      id="menus-select"
                      setValue={(ev) => handleChangeMenu(ev)}
                      value={selectedMenuId}
                      listOptions={menuListOptions}
                      disableCalcTrigger={true}
                    />
                  )}
                </div>
                <div className="tablet:flex w-full">
                  <div className="inline-flex space-x-2 justify-end order-2 flex-grow">
                    <Button className="mt-4" onClick={handleClickCancel}>
                      Cancel
                    </Button>
                    <Button
                      theme="primary"
                      className="mt-4"
                      onClick={handleSaveDashboard}
                    >
                      Save dashboard
                    </Button>
                  </div>
                  {!isNullEmptyOrWhitespace(dashboardId) && (
                    <div className="justify-start order-1">
                      <Button
                        theme="danger"
                        className="mt-4"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete the dashboard?"
                            )
                          )
                            handleDeleteDashboard();
                        }}
                      >
                        Delete dashboard
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

import { useMemo, useState } from "react";
import Form from "./Form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Fieldset from "components/forms/Fieldset";
import { Badge, Button } from "components/core";
import { isNull } from "helpers/stringUtilities";
import { ChevronRightIcon, ChevronLeftIcon } from "assets/icons";
import { groupObjectsFieldsBy } from "helpers/dataUtilities";
import useDeepCompareEffect, {
  useDeepCompareEffectNoCheck,
} from "use-deep-compare-effect";
import { redirectToListView } from "helpers/redirectUtilities";
import { getPenDataFromFormData } from "helpers/formUtilities";
import classNames from "classnames";
import Card from "components/Card";

export default function Generic({
  handleFormSubmit,
  dataStatus,
  farm,
  form,
  formValues,
  formValid,
  house,
  loaded,
  formProps,
  view,
  showSections = true,
  setAuditStatus,
  ...other
}) {
  const navigate = useNavigate();
  let location = useLocation();
  const { moduleName, formType, id } = useParams();

  const [groupedFields, setGroupedFields] = useState(undefined);
  const [sections, setSections] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState(undefined);

  const selectedSection = useMemo(() => {
    if (!sections?.length) return;

    return sections.find((section) => section.name === selectedSectionId);
  }, [sections, selectedSectionId]);

  //#region Callbacks

  //#endregion

  //#region Side-effects

  /**
   * Populate sections
   */
  useDeepCompareEffect(() => {
    if (isNull(form?.FormFields) || !showSections) return;

    const _sections = [];
    for (const field of form.FormFields) {
      if (!_sections.includes(field.Section)) {
        // Distinct only
        _sections.push(field.Section);
      }
    }

    // Add question count to each section
    const _sectionsWithCount = [];
    for (const section of _sections) {
      // count number of required form fields in section
      // Build an lookup array of required section refs
      const requiredFormFieldRefs = form?.FormFields.filter((ff) => {
        // console.log(ff.Ref, ff.Required instanceof Object)

        if (ff.Required instanceof Object) {
          // we don't know yet but on render we will know
          return ff.Section?.toLowerCase() === section?.toLowerCase();
        }

        return (
          ff.Required?.toLowerCase() === "d" &&
          ff.Section?.toLowerCase() === section?.toLowerCase()
        );
      }).map((ff) => ff.Ref);

      let requiredCount = 0;
      let completedCount = 0;
      for (const fv of formValid) {
        if (requiredFormFieldRefs.includes(fv.Ref)) {
          // Total
          if (fv.Required) requiredCount += 1;
          // Completed
          if (fv.Required && fv.Complete) completedCount += 1;
        }
      }

      _sectionsWithCount.push({
        name: section,
        requiredCount,
        completedCount,
      });
    }

    setSections(_sectionsWithCount);

    if (selectedSection === undefined && _sectionsWithCount.length) {
      // Default to first section
      setSelectedSectionId(_sectionsWithCount[0].name);
    }
  }, [form?.FormFields, formValid, showSections]);

  /**
   * Group fields by QuestionGroup property
   */
  useDeepCompareEffectNoCheck(() => {
    if (!form?.FormFields.length) return;

    const _groupedFields = {
      house: groupObjectsFieldsBy(
        form?.FormFields.filter((ff) => ff.Level.toLowerCase() === "h"),
        "QuestionGroup"
      ),
      pen: groupObjectsFieldsBy(
        form?.FormFields.filter((ff) => ff.Level.toLowerCase() === "p"),
        "QuestionGroup"
      ),
    };

    for (const [levelKey, level] of Object.entries(_groupedFields)) {
      for (const [groupKey, group] of Object.entries(level)) {
        // Filter by grouped section
        // Important: to ensure that field value is validated correctly and included in form values
        // the field must still render.
        if (showSections) {
          _groupedFields[levelKey][groupKey].render = false;
          for (const [fieldKey, field] of Object.entries(group.fields)) {
            // Add field property to hide/show fields individually
            if (field.Section === selectedSectionId) {
              _groupedFields[levelKey][groupKey].fields[fieldKey].render = true;
              _groupedFields[levelKey][groupKey].render = true;
            } else {
              _groupedFields[levelKey][groupKey].fields[
                fieldKey
              ].render = false;
            }
          }
        } else {
          _groupedFields[levelKey][groupKey].render = true;
        }
      }
    }

    setGroupedFields(_groupedFields);
  }, [
    form?.FormFields,
    selectedSectionId,
    form?.NonConformanceFormFieldTemplates,
    view,
  ]);

  // /**
  //  * Set audit status
  //  */
  // useDeepCompareEffect(() => {
  //   if (
  //     menuId?.toLowerCase() !== "audit" ||
  //     isNullEmptyOrWhitespace(form?.FormFields) ||
  //     isNullEmptyOrWhitespace(formValues)
  //   )
  //     return;

  //   // Build primary question lookup map
  //   const _primaryQuestionRefs = new Map();

  //   for (const field of form.FormFields) {
  //     if (
  //       !isNullEmptyOrWhitespace(field.QuestionGroup) &&
  //       field.QuestionGroup === field.Ref
  //     ) {
  //       _primaryQuestionRefs.set(field.Ref.toLowerCase(), field);
  //     }
  //   }

  //   //Build set of non-conformant primary question refs
  //   const _nonConformancePrimaryQuestionRefs = new Set();

  //   for (const pen of formValues) {
  //     for (const pv of pen.Values) {
  //       const _primaryQuestionField = _primaryQuestionRefs.get(
  //         pv.Ref?.toLowerCase()
  //       );

  //       if (_primaryQuestionField) {
  //         if (!_primaryQuestionField.ListOptions) {
  //           console.error(`No ListOptions for ${_primaryQuestionField.Ref}`);
  //         }

  //         const listOption = _primaryQuestionField.ListOptions?.find(
  //           (lo) => lo.Value === pv.Value
  //         );

  //         const _hasNonConformance = !isNullEmptyOrWhitespace(listOption)
  //           ? listOption.IsNcn?.toString().toLowerCase() === "true"
  //           : false;
  //         if (_hasNonConformance) {
  //           _nonConformancePrimaryQuestionRefs.add(pv.Ref.toLowerCase());
  //         }
  //       }
  //     }
  //   }

  //   const _newAuditStatus =
  //     _nonConformancePrimaryQuestionRefs.size === 0 &&
  //     dataStatus === DATA_STATUS.COMPLETE
  //       ? AUDIT_STATUS.CLOSED
  //       : AUDIT_STATUS.OPEN;

  //   console.log("_newAuditStatus", _nonConformancePrimaryQuestionRefs, _newAuditStatus, dataStatus);

  //   setAuditStatus(_newAuditStatus);
  // }, [form.FormFields, formValues, setAuditStatus]);

  //#endregion

  //#region Event handlers

  /**
   * Handle click cancel button
   */
  const handleClickCancel = () => {
    return navigate(redirectToListView(location, moduleName, formType));
  };

  const handleSelectSection = (value) => {
    setSelectedSectionId(value);
  };

  //#endregion

  const hasPenFields = form?.FormFields.some(
    (ff) => ff.Level.toLowerCase() === "p"
  );
  const numPens = house?.Pens?.length;
  const pens = [];
  if (hasPenFields && groupedFields !== undefined) {
    for (let index = 1; index <= numPens; index++) {
      pens.push(
        <Card>
          <Fieldset
            {...other}
            key={`fieldset-pen${index}`}
            title={`Pen ${index}`}
            text={`This section contains information regarding Pen ${index}.`}
            penNumber={index}
            groupedFields={groupedFields.pen}
            formValues={getPenDataFromFormData(index.toString(), formValues)}
            farm={farm}
            collapsible
          />
        </Card>
      );
    }
  }


  return (
    <Form
      {...formProps}
      onClickCancel={handleClickCancel}
      onFormSubmit={(ev, callback) => {
        handleFormSubmit(ev, { entryId: id, callback });
      }}
      dataStatus={dataStatus}
      loaded={loaded && sections !== undefined}
      showDraft={formType?.toLowerCase() === "audit"}
      button={
        sections?.length > 1 && selectedSectionId ? (
          <SectionIndicator
            sections={sections}
            selectedSection={selectedSection}
            onSelectSection={handleSelectSection}
          />
        ) : null
      }
      className="space-y-4"
    >
      <Card>
        <Fieldset
          {...other}
          key={`fieldset-house1`}
          penNumber="1"
          groupedFields={groupedFields?.house}
          formValues={getPenDataFromFormData("1", formValues)}
          farm={farm}
          title={selectedSectionId ?? `General`}
          text={selectedSectionId ? null : undefined}
          loaded={loaded && sections !== undefined}
        />
      </Card>
      {pens}
    </Form>
  );
}

const SectionSelectButton = ({ sections, onSelectSection, selectedSection }) => {
  return (
    <Button
      optionsContainerPosition="bottom"
      showMoreOptionsIcon={false}
      theme="text"
      options={sections?.map((s) => ({
        id: s.name,
        text: (
          <div className="flex flex-grow items-center">
            <div className="flex-grow ml-2 mr-2">{s.name}</div>
            {s.requiredCount > 0 && (
              <div
                className={classNames(
                  s.requiredCount > 0 && s.completedCount >= s.requiredCount
                    ? "text-success-500"
                    : "",
                  "items-end ml-2 text-xs"
                )}
                style={{ minWidth: "50px" }}
              >
                <Badge
                  theme={classNames(
                    s.requiredCount === s.completedCount
                      ? "success"
                      : "warning"
                  )}
                >
                  {s.completedCount}/{s.requiredCount}
                </Badge>
              </div>
            )}
          </div>
        ),
        onClick: () => onSelectSection(s.name),
      }))}
      optionsClassName="overflow-y-auto"
      optionsStyles={{ maxHeight: "60vh" }}
      optionProps={{ size: "small" }}
    >
      {selectedSection.name}
    </Button>
  );
};

const SectionIndicator = ({
  sections,
  selectedSection,
  onSelectSection,
  className,
  ...other
}) => {
  const selectedSectionIndex = sections.findIndex(
    (s) => s.name === selectedSection.name
  );

  return (
    <div {...other} className={`${className} text-gray-600 text-sm`}>
      <div className="flex space-x-3">
        <Button
          disabled={sections.length < 2 || selectedSectionIndex - 1 < 0}
          size="small"
          theme="text"
          icon={<ChevronLeftIcon className="w-4 h-4" />}
          onClick={() =>
            onSelectSection(sections[selectedSectionIndex - 1].name)
          }
        >
          <span className="sr-only">Prev</span>
        </Button>
        <SectionSelectButton sections={sections} onSelectSection={onSelectSection} selectedSection={selectedSection} />
        <Button
          disabled={
            sections.length < 2 ||
            selectedSectionIndex + 1 > sections.length - 1
          }
          theme="text"
          size="small"
          iconPosition="right"
          icon={<ChevronRightIcon className="w-4 h-4" />}
          onClick={() =>
            onSelectSection(sections[selectedSectionIndex + 1].name)
          }
        >
          <span className="sr-only">Next</span>
        </Button>
      </div>
    </div>
  );
};

import { useContext, useState } from "react";
import Form from "./Form";
import { Alert, Button, Fieldset } from "components/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormField from "./FormField";
import { groupObjectsFieldsBy } from "helpers/dataUtilities";
import useDeepCompareEffect from "use-deep-compare-effect";
import { redirectToListView } from "helpers/redirectUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { LOADED_STATUS } from "constants.js";
import { getPenDataFromFormData } from "helpers/formUtilities";
import { AppDataContext } from "context/AppDataProvider";
import Card from "components/Card";

export default function GenericNonConformance({
  handleFormSubmit,
  dataStatus,
  farm,
  form,
  formValues,
  formValid,
  house,
  loaded,
  formProps,
  view,
  showSections = true,
  parentForm,
  parentFormData,
  ...other
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { moduleName, id, formType } = useParams();
  const { config } = useContext(AppDataContext);

  const [groupedFields, setGroupedFields] = useState({});
  const [parentGroupFields, setParentGroupFields] = useState({});
  // const [countNonConformances, setCountNonConformances] = useState(0);

  const [filteredParentFormData, setNonConformantParentFormData] = useState({});
  const [loadedStatus, setLoadedStatus] = useState(LOADED_STATUS.LOADING);

  //#region Callbacks

  //#endregion

  //#region Side-effects

  /**
   * Parent Group fields by QuestionGroup property
   */
  useDeepCompareEffect(() => {
    if (
      config.enableNonconformance !== "true" ||
      isNullEmptyOrWhitespace(parentForm?.FormFields) ||
      isNullEmptyOrWhitespace(parentFormData)
    )
      return;

    // Build primary question lookup map
    const _primaryQuestionRefs = new Map();

    for (const field of parentForm.FormFields) {
      if (
        !isNullEmptyOrWhitespace(field.QuestionGroup) &&
        field.QuestionGroup === field.Ref
      ) {
        _primaryQuestionRefs.set(field.Ref.toLowerCase(), field);
      }
    }

    //Build set of non-conformant primary question refs
    const _nonConformancePrimaryQuestionRefs = new Set();

    for (const pen of parentFormData.PenValues) {
      for (const pv of pen.Values) {
        const _primaryQuestionField = _primaryQuestionRefs.get(
          pv.Ref?.toLowerCase()
        );

        if (_primaryQuestionField) {
          if (!_primaryQuestionField.ListOptions) {
            console.error(`No ListOptions for ${_primaryQuestionField.Ref}`);
          }

          const listOption = _primaryQuestionField.ListOptions?.find(
            (lo) => lo.Value === pv.Value
          );

          const _hasNonConformance = !isNullEmptyOrWhitespace(listOption)
            ? listOption.IsNcn?.toString().toLowerCase() === "true"
            : false;
          if (_hasNonConformance) {
            _nonConformancePrimaryQuestionRefs.add(pv.Ref.toLowerCase());
          }
        }
      }
    }

    // setCountNonConformances(_nonConformancePrimaryQuestionRefs.size);

    //Filter out form values where primary question is conformant
    const _nonConformantParentFormData = {
      ...parentFormData,
      PenValues: parentFormData.PenValues.map((pen) => {
        const _penValues = pen.Values.filter((pv) => {
          return _nonConformancePrimaryQuestionRefs.has(
            pv.QuestionGroup?.toLowerCase()
          );
        });
        return {
          ...pen,
          Values: _penValues,
        };
      }),
    };

    setNonConformantParentFormData(_nonConformantParentFormData);

    const _filteredFormFields = parentForm.FormFields.filter((ff) =>
      _nonConformancePrimaryQuestionRefs.has(ff.QuestionGroup?.toLowerCase())
    );

    const _groupedFields = {
      house: groupObjectsFieldsBy(
        _filteredFormFields.filter((ff) => ff.Level.toLowerCase() === "h"),
        "QuestionGroup"
      ),
      pen: groupObjectsFieldsBy(
        _filteredFormFields.filter((ff) => ff.Level.toLowerCase() === "p"),
        "QuestionGroup"
      ),
    };

    setParentGroupFields(_groupedFields);
  }, [parentForm.FormFields, parentFormData]);

  /**
   * Group fields by QuestionGroup property
   */
  useDeepCompareEffect(() => {
    if (config.enableNonconformance !== "true" || !form?.FormFields.length)
      return;

    const _groupedFields = {
      house: groupObjectsFieldsBy(
        form.FormFields.filter((ff) => ff.Level.toLowerCase() === "h"),
        "QuestionGroup"
      ),
      pen: groupObjectsFieldsBy(
        form.FormFields.filter((ff) => ff.Level.toLowerCase() === "p"),
        "QuestionGroup"
      ),
    };

    setGroupedFields(_groupedFields);
    setLoadedStatus(LOADED_STATUS.LOADED);
  }, [form.FormFields]);

  // useEffect(() => {
  //   if (
  //     isNullEmptyOrWhitespace(formValues?.Status) ||
  //     isNullEmptyOrWhitespace(parentFormData?.Status)
  //   )
  //     return;

  //   const _isCompleteParentForm =
  //     parentFormData.Status === DATA_STATUS.COMPLETE;
  //   const _isCompleteThisForm = formValues.Status === DATA_STATUS.COMPLETE;
  //   const _newAuditStatus =
  //     _isCompleteParentForm && _isCompleteThisForm
  //       ? AUDIT_STATUS.CLOSED
  //       : AUDIT_STATUS.OPEN;

  //   setAuditStatus(_newAuditStatus);
  // }, [formValues?.Status, parentFormData?.Status, setAuditStatus]);

  //#endregion

  //#region Event handlers

  /**
   * Handle click cancel button
   */
  const handleClickCancel = () => {
    return navigate(redirectToListView(location, moduleName, formType));
  };

  //#endregion

  if (config.enableNonconformance !== "true") {
    return <Alert theme="danger">Non-conformance is not enabled.</Alert>;
  }

  return loadedStatus === LOADED_STATUS.LOADED ? (
    Object.keys(parentGroupFields.house).length > 0 ? (
      <Form
        {...formProps}
        onClickCancel={handleClickCancel}
        onFormSubmit={(ev, callback) => {
          handleFormSubmit(ev, { entryId: id, callback });
        }}
        dataStatus={dataStatus}
        loaded={loaded}
        showDraft={formType?.toLowerCase() === "audit"}
      >
        <div className="space-y-4">
          {Object.entries(parentGroupFields.house)?.map(
            ([key, group], groupIndex) => {
              if (isNullEmptyOrWhitespace(group)) return null;

              return (
                <Card>
                  <Fieldset
                    className="py-4"
                    childWrapperClass="grid grid-cols-4 gap-4"
                    isFullWidth={false}
                    content={
                      <div className="space-y-4">
                        {group.fields?.map((field, fieldIndex) => {
                          if (!parentFormData?.PenValues) return null;

                          return (
                            <FormField
                              {...other}
                              key={`${field.Ref}-${field.Level}`}
                              field={field}
                              formValues={getPenDataFromFormData(
                                "1",
                                filteredParentFormData
                              )}
                              penNumber={1}
                              farm={farm}
                              groupFieldIndex={
                                !isNullEmptyOrWhitespace(key)
                                  ? fieldIndex
                                  : null
                              }
                              // labelPosition={field.Ref?.toLowerCase() === field.QuestionGroup?.toLowerCase() ? "top" : undefined}
                              labelPosition={"top"}
                              labelSize={
                                field.Ref?.toLowerCase() ===
                                field.QuestionGroup?.toLowerCase()
                                  ? "large"
                                  : undefined
                              }
                            />
                          );
                        })}
                      </div>
                    }
                  >
                    {/* Only question groups should contain non-conformance questions */}
                    {key !== "null" &&
                      groupedFields?.house?.null?.fields?.map(
                        (field, fieldIndex) => {
                          if (!formValues) return null;

                          return (
                            <FormField
                              {...other}
                              key={`${field.Ref}-${field.Level}`}
                              field={{ ...field, QuestionGroup: key }}
                              formValues={getPenDataFromFormData(
                                "1",
                                formValues
                              )}
                              penNumber={1}
                              farm={farm}
                              groupFieldIndex={
                                !isNullEmptyOrWhitespace(key)
                                  ? fieldIndex
                                  : null
                              }
                            />
                          );
                        }
                      )}
                  </Fieldset>
                </Card>
              );
            }
          )}
        </div>
      </Form>
    ) : (
      <div className="flex flex-col flex-grow justify-center py-4 px-4 tablet:text-center">
        <h1 className="text-3xl mb-3">
          '{parentForm.FormTitle}' form is compliant
        </h1>
        <p className="mb-3">
          No non-conformances found for form '{parentForm.FormTitle}'.
        </p>
        <div className="flex justify-center">
          <Button onClick={handleClickCancel}>Return to list</Button>
        </div>
      </div>
    )
  ) : (
    <FieldsetSkeleton />
  );
}

import { ChevronRightIcon } from "assets/icons/ChevronRightIcon";
import { XIcon } from "assets/icons/XIcon";
import React, { useEffect, useState, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Badge } from "../Badges";
import List from "../Lists/List";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { Input, Label } from "..";
import ListItem from "../Lists/ListItem";
import useDeepCompareEffect from "use-deep-compare-effect";
import { isEqual } from "helpers/comparisionUtilities";
import { SearchIcon } from "assets/icons";
import useDebounceEffect from "hooks/useDebounceEffect";
import useCalcTrigger from "hooks/useCalcTrigger";

const ReactSortable = lazy(() =>
  import("react-sortablejs").then((module) => ({
    default: module.ReactSortable,
  }))
);

/**
 * A multi-select input component
 * @example
 * <MultiSelect
 *   label="Example multi-select"
 *   id="example-multiselect"
 *   validate={() => {}}
 *   setValue={() => {}}
 *   setValid={() => {}}
 *   value={value}
 *   listOptions={[{ Id: "1", Text: "Option 1", Value: "1" }, { Id: "2", Text: "Option 2", Value: "2" }]}
 * />
 */
const MultiSelect = ({
  label,
  id,
  hint,
  validate,
  value,
  setValue,
  setValid,
  required = true,
  disabled = false,
  labelPosition = "top",
  labelSize,
  style,
  dependencies = [],
  className = "",
  inputClassName = "",
  defaultValue,
  theme,
  size,
  render = true,
  listOptions,
  asPortal = false, // If true, will render the component as a portal
  showSearch = false, // If true, will show the search input,
  sortable = false, // If true, will allow the list to be sortable
  limit = 0, // If greater than 0, will limit the number of selected items
  addonLeft,
  addonRight,
  disableCalcTrigger,
  ...other
}) => {
  useCalcTrigger(value, setValue, disableCalcTrigger);

  const _listOptionsForDeepCompare =
    listOptions?.map((option) => {
      return {
        id: option.Id,
        // Ignore `text` as this could be React component and...
        // ...result in max callstack issue: https://github.com/kentcdodds/use-deep-compare-effect/issues/7
        // text: option.Text,
        value: option.Value,
      };
    }) ?? [];
  const _listOptionsById = listOptions?.reduce((acc, curr) => {
    acc[curr.Id] = curr;
    return acc;
  }, {});

  // state showing if dropdown is open or closed
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(value?.length > 0);
  const [selectedIds, setSelectedIds] = useState(
    () =>
      convertCSVValueToSelectedIds(value, listOptions)?.map((id) => ({ id })) ??
      []
  );

  // Popper
  const [buttonElement, setButtonElement] = useState(undefined);
  const [popperElement, setPopperElement] = useState(undefined);
  const { styles, attributes } = usePopper(buttonElement, popperElement, {
    modifiers: [
      { name: "offset", options: { offset: [0, -10] } },
      // { name: "arrow", options: { element: arrowElement } },
    ],
  });

  const inputErrorClasses = ["border-danger-600"];
  const inputClasses = [
    "rounded-md",
    "border",
    "focus:ring-4 focus:ring-offset-0",
    `${disabled ? "disabled:opacity-50" : ""}`,
  ];
  if (!isNullEmptyOrWhitespace(inputClassName))
    inputClasses.push(inputClassName);
  const errorIconClasses = [];

  // Size
  if (size === "sm") {
    inputClasses.push("py-2");
  } else {
    inputClasses.push("py-3");
  }

  // Theme
  if (theme === "darkgray") {
    inputClasses.push(
      "bg-gray-900 border-gray-500 text-gray-400 focus:border-gray-400 focus:ring-gray-800"
    );
    errorIconClasses.push("bg-gray-900");
  } else {
    inputClasses.push(
      "bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
    );
    errorIconClasses.push("bg-white");
  }

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} newValue  The new input value.
   */
  function setInputValue(newValue) {
    // Prevent setting input to null or undefined
    // Controlled components should not have null or undefined value
    if (typeof newValue === "undefined" || newValue === null) return;

    if (!isEqual(newValue?.toString(), value?.toString())) {
      setValue(newValue);
    }
    setHasValue(newValue.length > 0);
  }

  /**
   * Validate the input value.
   * @returns {string|null} The error message or null if valid.
   */
  const validation = () => {
    // Add custom validation here...
    // if (required && !value) {
    //   return `${label} is required.`;
    // }
    if (validate) return validate(value);
  };

  //#endregion

  //#region Side-effects

  /**
   * Watch for prop values changes
   */
  useDeepCompareEffect(() => {
    if (listOptions === undefined) return; // Prevent setting value on initial render

    const _newSelectedItems =
      convertCSVValueToSelectedIds(value, listOptions)?.map((id) => ({ id })) ??
      [];
    setSelectedIds(_newSelectedItems);
  }, [value, _listOptionsForDeepCompare]);

  /**
   * Update form values when selected items change
   */
  useDeepCompareEffect(() => {
    if (isNullEmptyOrWhitespace(listOptions)) return; // Prevent setting value on initial render

    const newValue = convertSelectedItemIdsToCSVValue(
      selectedIds?.map((sid) => sid.id),
      listOptions
    );

    // Prevent resetting same state value
    setInputValue(newValue);
  }, [selectedIds]);

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult);

    const complete = required && isNullEmptyOrWhitespace(value) ? false : true;
    if (setValid) setValid(!validationResult, value, required, complete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, value, required]);

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (touched || !defaultValue || !setInputValue) return;

    if (isNullEmptyOrWhitespace(value)) {
      setInputValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, defaultValue, touched]);

  //#endregion

  //#region Event handlers

  const handleOptionOnClick = (selectedId) => {
    function upsertSelectedIds() {
      const newSelectedIds = selectedIds?.map((sid) => sid.id) ?? [];

      const index = newSelectedIds.findIndex((item) => item === id);
      if (index === -1) {
        newSelectedIds.push(selectedId);
      } else {
        newSelectedIds.splice(index, 1, selectedId);
      }

      setSelectedIds(newSelectedIds?.map((sid) => ({ id: sid })) ?? []);
    }

    upsertSelectedIds();
    setTouched(true);
  };

  // removes item from multiselect
  const handleRemoveTag = (ev, id) => {
    ev.stopPropagation(); // Prevent opening the dropdown

    setSelectedIds(
      selectedIds
        .filter((sid) => sid.id !== id)
        ?.map((sid) => ({ id: sid.id })) ?? []
    );
    setTouched(true);
  };

  //#endregion

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  const portalContainer = document.getElementsByTagName("BODY")[0];

  return (
    <Popover {...other}>
      {({ open }) => (
        <>
          <div
            className={`${className} ${
              labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative"
            }`}
          >
            {label && (
              <Label
                id={id}
                text={label}
                required={required}
                focused={focused}
                hasValue={hasValue}
                position={labelPosition}
                size={labelSize}
                theme={theme}
                erroneous={touched && error && !focused}
              />
            )}
            <div
              className={`mt-1 ${labelPosition === "left" ? "col-span-2" : ""}`}
            >
              <div className="relative">
                <Popover.Button
                  as="div"
                  aria-labelledby={id}
                  data-cy="multiselect"
                  className="w-full relative cursor-pointer "
                  ref={setButtonElement}
                  // onClick={(ev) => {
                  //   ev.preventDefault();
                  //   ev.stopPropagation(); // Prevent opening the dropdown
                  // }}
                >
                  <div className="flex flex-col items-center relative">
                    <div className="w-full ">
                      {/* <div className="my-2 p-1 flex border border-gray-200 bg-white rounded "> */}
                      <div
                        className={`w-full tablet:text-sm  flex ${
                          theme ? theme : ""
                        } ${inputClasses.join(" ")} ${
                          touched && error && !focused
                            ? inputErrorClasses.join(" ")
                            : ""
                        }`}
                        onClick={() => setFocused(true)}
                      >
                        <div className="flex flex-auto flex-wrap px-2">
                          {!sortable ? (
                            selectedIds?.length > 0 ? (
                              <SelectedItemBadges
                                selectedItems={selectedIds.map((sid) => ({
                                  Id: sid.id,
                                  Value: _listOptionsById[sid.id]?.Value,
                                  Text: _listOptionsById[sid.id]?.Text,
                                }))}
                                handleRemoveTag={handleRemoveTag}
                              />
                            ) : (
                              <div className="text-sm text-gray-500">
                                - Select -
                              </div>
                            )
                          ) : selectedIds.length > 0 ? (
                            `${selectedIds.length} selected`
                          ) : (
                            <div className="text-sm text-gray-500">
                              - Select -
                            </div>
                          )}
                        </div>
                        {listOptions?.filter(
                          (option) =>
                            !selectedIds
                              ?.map((sid) => sid.id)
                              .includes(option.Id)
                        ).length > 0 && (
                          <div className="text-gray-300 border-l flex items-center border-gray-300">
                            <button
                              type="button"
                              className="text-gray-600 outline-none focus:outline-none"
                            >
                              <ChevronRightIcon
                                className={`h-5 w-5 transition-all duration-500 ease-in-out ${
                                  !open
                                    ? "rotate-0 text-gray-300"
                                    : "rotate-90 text-primary"
                                }`}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Popover.Button>
                <div className="">
                  {/* Hack to all sortable elements to work inside of Popovers, we need to move it outside the Popover.Button element */}
                  {sortable ? (
                    selectedIds?.length > 0 ? (
                      <Suspense
                        fallback={
                          <div className="text-sm text-gray-500">
                            Loading selected items...
                          </div>
                        }
                      >
                        <ReactSortable
                          list={selectedIds}
                          setList={(values) => {
                            setSelectedIds(values);
                          }}
                          // draggable=".react-sortable-handle"
                          preventOnFilter={true}
                          onClick={(ev) => {
                            console.log(ev.target.classList);
                            // ev.stopPropagation();
                          }}
                        >
                          <SelectedItemBadges
                            selectedItems={selectedIds.map((sid) => ({
                              Id: sid.id,
                              Value: _listOptionsById[sid.id]?.Value,
                              Text: _listOptionsById[sid.id]?.Text,
                            }))}
                            handleRemoveTag={handleRemoveTag}
                            onClick={(ev) => {
                              console.log(ev.target.classList);
                            }}
                          />
                        </ReactSortable>
                      </Suspense>
                    ) : null
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {asPortal && portalContainer ? (
            ReactDOM.createPortal(
              <PopoverPanelContent
                id={id}
                styles={styles}
                attributes={attributes}
                setPopperElement={setPopperElement}
                items={listOptions?.filter(
                  (option) =>
                    !selectedIds?.map((sid) => sid.id).includes(option.Id)
                )}
                selectedItems={selectedIds?.map((sid) => sid.id)}
                handleOptionOnClick={handleOptionOnClick}
                showSearch={showSearch}
                limit={limit}
              />,
              portalContainer
            )
          ) : (
            <PopoverPanelContent
              id={id}
              styles={styles}
              attributes={attributes}
              setPopperElement={setPopperElement}
              items={listOptions?.filter(
                (option) =>
                  !selectedIds?.map((sid) => sid.id).includes(option.Id)
              )}
              selectedItems={selectedIds?.map((sid) => sid.id)}
              handleOptionOnClick={handleOptionOnClick}
              showSearch={showSearch}
              limit={limit}
            />
          )}
        </>
      )}
    </Popover>
  );
};

function convertCSVValueToSelectedIds(value, listOptions) {
  if (isNullEmptyOrWhitespace(listOptions)) return [];

  const _listOptionsByValue = listOptions?.reduce((acc, curr) => {
    acc[curr.Value] = curr;
    return acc;
  }, {});

  const _splitValue = value?.toString()?.split(",");
  if (!_splitValue) return [];

  const _selectedItems =
    _splitValue?.filter(Boolean).map((value) => {
      const _matchingOption = _listOptionsByValue[value];

      return _matchingOption?.Id;
    }) ?? [];

  return _selectedItems;
}

function convertSelectedItemIdsToCSVValue(selectedItems, listOptions) {
  if (isNullEmptyOrWhitespace(listOptions)) return "";

  const _listOptionsById = listOptions?.reduce((acc, curr) => {
    acc[curr.Id] = curr;
    return acc;
  }, {});

  const _selectedItems = selectedItems?.map((id) => {
    const _matchingOption = _listOptionsById[id];
    return _matchingOption?.Value;
  });

  return _selectedItems?.join(",");
}

function PopoverPanelContent({
  id,
  styles,
  attributes,
  setPopperElement,
  items,
  selectedItems,
  handleOptionOnClick,
  showSearch,
  limit,
  ...other
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  //#region Side-effects

  /**
   * Filter items
   */
  useDebounceEffect(
    () => {
      if (isNull(items)) return;

      const _filteredItems = items.filter(
        (item) =>
          isNullEmptyOrWhitespace(searchQuery) ||
          Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
      );

      setSearchResults(_filteredItems);
    },
    [searchQuery, items],
    500
  );

  //#endregion

  /**
   * Handle change search string
   * @param {string} value
   */
  const handleChangeSearchQuery = (value) => {
    setSearchQuery(value);
  };

  return (
    <Popover.Panel
      ref={(ref) => setPopperElement(ref)}
      style={{ ...styles.popper, zIndex: 9999 }}
      className="mt-3 px-2 w-screen max-w-xs"
      {...attributes.popper}
    >
      {({ close }) => (
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="relative bg-white px-5 py-6">
            {showSearch && (
              <div className="pb-4">
                <Input
                  id="search"
                  placeholder="Filter..."
                  value={searchQuery}
                  setValue={handleChangeSearchQuery}
                  required={false}
                  addonLeft={<SearchIcon className="w-4" />}
                  addonRight={
                    searchQuery && (
                      <XIcon
                        className="w-4"
                        onClick={() => setSearchQuery("")}
                      />
                    )
                  }
                  addonRightInteractable={true}
                  size="sm"
                  disableCalcTrigger={true}
                />
              </div>
            )}
            {searchResults.length > 0 ? (
              limit > 0 && selectedItems.length >= limit ? (
                <p className="text-sm mt-2">Maximum selections reached.</p>
              ) : (
                <List
                  id={`list-${id}`}
                  theme="striped"
                  size="small"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  {searchResults?.map((option) => (
                    <ListItem
                      id={`menu-item-${option.Id}`}
                      key={`menu-item-${option.Id}`}
                      onClick={() => handleOptionOnClick(option.Id)}
                    >
                      {option.Text}
                    </ListItem>
                  ))}
                </List>
              )
            ) : (
              <p className="text-sm mt-2">No items found.</p>
            )}
          </div>
        </div>
      )}
    </Popover.Panel>
  );
}

function SelectedItemBadges({ selectedItems, handleRemoveTag }) {
  return selectedItems.map((item) => {
    return (
      <Badge
        key={`badge-${item.Value}`}
        className="mr-1 cursor-pointer leading-none react-sortable-handle"
        theme="secondary"
        iconPosition="right"
        icon={<XIcon className="w-4 h-4" />}
        title="Click to remove tag"
        onClick={(ev) => {
          handleRemoveTag(ev, item.Id);
        }}
      >
        <div
          onClick={(ev) => {
            console.log(ev.target);
            // handleRemoveTag(ev, item.Id)
          }}
        >
          {item.Text}
        </div>
      </Badge>
    );
  });
}

MultiSelect.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listOptions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      Text: PropTypes.string.isRequired,
      Value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
  setValue: PropTypes.func,
  setValid: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  labelPosition: PropTypes.oneOf(["top", "left", "inset"]),
  labelSize: PropTypes.oneOf(["large", "small", undefined]),
  style: PropTypes.object,
  dependencies: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  defaultValue: PropTypes.string,
  theme: PropTypes.oneOf(["primary", "secondary", "success", "danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  render: PropTypes.bool,
  asPortal: PropTypes.bool,
  showSearch: PropTypes.bool,
  sortable: PropTypes.bool,
  limit: PropTypes.number,
};

export { MultiSelect };

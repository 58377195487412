import { ISQLDateObject } from "./formUtilities";

export interface IFarm {
  FarmCode: string;
  FarmGroup: string;
  FarmName: string;
  FarmType: string;
  Houses: IFarmHouse[];
}

export interface IFarmHouse {
  HouseLabel: string;
  HouseNumber: number;
  WaterMeterType: string;
  Pens: IFarmPen[];
}

export interface IFarmPen {
  PenNumber: number;
  BirdsAlive: number;
  Placement: IFarmPlacement;
}

export interface IFarmPlacement {
  HatchDate: string;
  _HatchDate: ISQLDateObject;
  CropDate: string;
  _CropDate: ISQLDateObject;
  DatePlaced: string;
  _DatePlaced: ISQLDateObject;
  DepopDate: string;
  BirdsPlaced: number;
  BirdSex: string;
  BirdType: string;
}

export function getFarm(farms: IFarm[], farmCode: string): IFarm | null {
  return farms.find(
    (farm) => farm.FarmCode?.toLowerCase() === farmCode.toLowerCase()
  ) ?? null;
}

export function getFarmHouse(farm: IFarm, houseNumber: number): IFarmHouse | null {
  return farm.Houses.find((house) => house.HouseNumber === houseNumber) ?? null;
}

export function getFarmHousePen(
  farmHouse: IFarmHouse,
  penNumber: number
): IFarmPen | undefined {
  return farmHouse?.Pens?.find((pen) => pen.PenNumber === penNumber);
}

export function getPlacementFromHouse(house: IFarmHouse) {
  if (!house?.Pens?.length) return undefined;

  return house.Pens.find((p) => p.PenNumber.toString() === "1")?.Placement;
}

import cookies from "js-cookie";

export function deleteCookie(name, {expires, path = "/"} = {}) {
  cookies.remove(name, {expires, path});
}

export function setCookie(name, value, {expires, path = "/"} = {}) {
  cookies.set(name, value, {expires, path});
}

export function getCookie(name) {
  return cookies.get(name)
}

export function storePendingRequest(value) {
  if (typeof Storage === "undefined")
    return new Error("Sorry, your browser does not support web storage.");

  if (localStorage.pendingRequests) {
    const pendingRequests = JSON.parse(localStorage.pendingRequests);
    pendingRequests.push(value);
    localStorage.pendingRequests = JSON.stringify(pendingRequests);
  } else {
    localStorage.pendingRequests = JSON.stringify([value]);
  }
}

/**
 * 
 * @param {*} value 
 * @returns 
 * @deprecated  Removed in future releases.
 */
export function isPendingRequest(value) {
  if (typeof Storage === "undefined")
    return new Error("Sorry, your browser does not support web storage.");
  if (!localStorage.pendingRequests) return false;

  const pendingRequests = JSON.parse(localStorage.pendingRequests);
  return pendingRequests?.some(item => item.startsWith(value));
}


export function clearBrowserCache() {
  try {
    const cachesToKeep = ["images", ""];

    caches.keys().then((keyList) => {
      return Promise.all(
        keyList
          .filter(
            (key) =>
              key.startsWith("workbox-") === false &&
              cachesToKeep.indexOf(key) === -1
          )
          .map((key) => {
            return caches.delete(key);
          })
      );
    });
  } catch (err) {
    console.error("An error occurred while clearing browser cache.");
  }
}

import Card, { CardTitle } from "components/Card";
import ActivePageDashboard from "components/Dashboard/ActivePageDashboard";
import RecentVisits from "components/RecentVisits";
import { IMenuItem } from "components/Sidebar";
import UsefulLinks from "components/UsefulLinks";
import { AppDataContext } from "context/AppDataProvider";
import { useContext, useEffect } from "react";

interface HomeProps {
  className?: string;
  menu: IMenuItem;
}

export default function Home({ menu, className }: HomeProps) {
  const { user, setPageTitle, pageTitle } = useContext(AppDataContext);

  // const [quoteOfTheDay, setQuoteOfTheDay] = useState<string>("");

  // /**
  //  * Mount/unmount
  //  */
  // useEffect(() => {
  //   fetch("/api/inspirationalquote-get")
  //     .then(function (response) {
  //       return response.text();
  //     })
  //     .then(function (quote) {
  //       setQuoteOfTheDay(quote);
  //     });
  // }, []);

  /**
   * Set page title
   */
  useEffect(() => {
    if (user?.FullName !== undefined) {
      setPageTitle(`Welcome ${user.FullName}!`);
    }
  }, [setPageTitle, user?.FullName]);

  return (
    <main className="flex flex-col flex-grow p-4">
      <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2">
        <div className="col-span-full">
          <Card>
            <div className="text-2xl font-medium mb-2">{pageTitle}</div>
            {/* <p className="text-gray-600">{quoteOfTheDay}</p> */}
          </Card>
        </div>

        <div>
          <Card>
            <CardTitle>Useful links</CardTitle>
            <UsefulLinks className="-mx-4" />
          </Card>
        </div>

        <div>
          <Card>
            <CardTitle>Recent visits</CardTitle>
            <RecentVisits className="-mx-4" />
          </Card>
        </div>

        <div className="col-span-full">
          <ActivePageDashboard />
        </div>
      </div>
    </main>
  );
}

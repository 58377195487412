import classNames from "classnames";
import { AppDataContext } from "context/AppDataProvider";
import { IForm } from "helpers/formUtilities";
import { useContext, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { generateInitialsFromText } from "helpers/stringUtilities";
// import { DotsVerticalIcon } from "assets/icons";
import { Link } from "react-router-dom";
import { redirectWithExistingSearchParams } from "helpers/redirectUtilities";
import ListSkeleton from "components/core/Lists/ListSkeleton";

export default function AdminAuditListPage() {
  const { forms } = useContext(AppDataContext);

  const location = useLocation();

  const { moduleName, formType } = useParams();

  const filteredForms = useMemo<IForm[]>(
    () =>
      (forms as IForm[]).filter(
        (f) =>
          f.FormType?.toLowerCase() === formType?.toLowerCase() &&
          f.ModuleName?.toLowerCase() === moduleName?.toLowerCase()
      ),
    [forms, formType, moduleName]
  );

  return (
    <div className="flex flex-col flex-grow">
      <main className="flex flex-col flex-grow">
        <div className="flex flex-col flex-grow w-full mx-auto py-6 tablet:px-6 desktop:px-8">
          {moduleName !== undefined && formType !== undefined ? (
            <ul className="mt-3 grid grid-cols-1 gap-5 tablet:gap-6 tablet:grid-cols-2 laptop:grid-cols-4">
              {filteredForms.map((form) => (
                <li key={form.FormName}>
                  <Link
                    to={redirectWithExistingSearchParams(
                      location,
                      `/admin/audit/${form.FormName}`
                    )}
                    className="focus:outline-none group col-span-1 flex shadow-sm rounded-md relative"
                  >
                    <div
                      className={classNames(
                        "bg-primary group-hover:bg-primary-dark",
                        "shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                      )}
                    >
                      {generateInitialsFromText(form.FormTitle)}
                    </div>
                    <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                      <div className="flex-1 px-4 py-2 text-sm truncate">
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                        <div className="text-gray-900 font-medium group-hover:text-primary">
                          {form.FormTitle}
                        </div>

                        <p className="text-gray-500">{form.FormSubTitle}</p>
                      </div>
                      {/* <div className="shrink-0 pr-2">
                    <button
                      type="button"
                      className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div> */}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <ListSkeleton />
          )}
        </div>
      </main>
    </div>
  );
}

import React, { useContext, useState, useEffect, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import Historical from "components/Historical";
import { useNavigate } from "react-router-dom";
import { AppDataContext, useMenus } from "context/AppDataProvider";
import useQuery from "hooks/useQuery";
import ButtonNew from "components/Historical/ButtonNew";
import useDeepCompareEffect from "use-deep-compare-effect";
import ButtonSkeleton from "components/core/Buttons/ButtonSkeleton";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { redirectToFormView } from "helpers/redirectUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { deepClone } from "helpers/dataUtilities";
import Card from "components/Card";
import { IFarm } from "helpers/farmUtilities";
import { IForm, IFormData } from "helpers/formUtilities";
import { IButtonNew } from "components/Historical/types";
import ActivePageDashboard from "components/Dashboard/ActivePageDashboard";
// import FarmSelector from "components/FarmSelector";
// import HouseSelector from "components/HouseSelector";
// import MenuSelector from "components/MenuSelector";
import Breadcrumb from "components/Breadcrumb";

interface ListPageProps {
  className?: string;
}

export default function ListPage(props: ListPageProps) {
  let { formType, moduleName } = useParams();
  const { activeMenu } = useMenus();
  let location = useLocation();
  let navigate = useNavigate();

  let query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");
  const { fetchFormValues, farms, forms, pageTitle, setPageTitle } =
    useContext(AppDataContext);

  const farm = useMemo<IFarm | undefined>(() => {
    if (farmId === null) {
      return undefined;
    }

    return farms.find(
      (f: IFarm) => f.FarmCode.toLowerCase() === farmId.toLowerCase()
    );
  }, [farms, farmId]);

  // const house = useMemo(() => {
  //   if (houseId === null || farm === undefined) {
  //     return undefined;
  //   }

  //   return farm.Houses?.find(
  //     (h) => h.HouseNumber.toString() === houseId.toString()
  //   );
  // }, [farm, houseId]);

  const filteredForms = useMemo(() => {
    let filteredForms = forms.filter(
      (f) =>
        f.FormType?.toLowerCase() === formType?.toLowerCase() &&
        f.ModuleName?.toLowerCase() === moduleName?.toLowerCase()
    );

    const formsClone = deepClone(filteredForms) as IForm[];

    formsClone.forEach((f) => {
      // Filter form fields by FarmType
      if (farm?.FarmType) {
        f.FormFields = f.FormFields?.filter(
          (ff) =>
            isNullEmptyOrWhitespace(ff.FarmType) ||
            ff.FarmType?.split(",").some(
              (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
            )
        );
      }

      // Filter form fields by FarmGroup
      if (farm?.FarmGroup) {
        f.FormFields = f.FormFields?.filter(
          (ff) =>
            isNullEmptyOrWhitespace(ff.FarmGroup) ||
            ff.FarmGroup?.split(",").some(
              (fg) => fg.toLowerCase() === farm.FarmGroup.toLowerCase()
            )
        );
      }
    });

    return formsClone;
  }, [forms, formType, moduleName, farm?.FarmType, farm?.FarmGroup]);

  const [formData, setFormData] = useState<IFormData[] | undefined>(undefined);
  const [newButtonOptions, setNewButtonOptions] = useState<
    IButtonNew[] | undefined
  >(undefined);

  //#region Side-effects

  /**
   * Set form data
   */
  useDeepCompareEffect(() => {
    const abortController = new AbortController();

    // Forms not yet loaded
    if (!filteredForms.length || farmId === null || houseId === null) {
      setFormData(undefined);
      return;
    }

    const formIds = (filteredForms as IForm[])
      .filter((f) => f.HasFormValues)
      .map((f) => ({
        formId: f.FormName,
        formType: f.FormType,
        moduleId: f.ModuleName,
      }));

    fetchFormValues(farmId, houseId, formIds, abortController.signal)
      .then((result: IFormData[]) => {
        if (abortController.signal.aborted) return;

        const newFormData = result
          ?.filter((r) => r !== undefined)
          // Flatten array of responses in single response
          .flat()
          // Sort chronologically after flattening array
          .sort(
            (a, b) =>
              b._DateApplies?.normalised.getTime() -
                a._DateApplies?.normalised.getTime() ||
              b._LastModified?.normalised.getTime() -
                a._LastModified?.normalised.getTime()
          );

        setFormData(newFormData);
      })
      .catch((error) => {
        if (abortController.signal.aborted) return;

        console.error(error.message);
      });
  }, [farmId, fetchFormValues, filteredForms, filteredForms.length, houseId]);

  /**
   * Set page title
   */
  useEffect(() => {
    setPageTitle(activeMenu?.Title ?? "");
  }, [location.pathname, setPageTitle, activeMenu?.Title]);

  /**
   * Set new button options
   */
  useDeepCompareEffect(() => {
    const _forms = filteredForms.filter(
      (f) =>
        f.Permissions.includes("view") &&
        f.Permissions.includes("create") &&
        f.FormType?.toLowerCase() === formType?.toLowerCase()
    );

    setNewButtonOptions(
      _forms.map((f) => ({
        FormName: f.FormName,
        FormTitle: f.FormTitle,
        FormType: f.FormType,
      }))
    );
  }, [filteredForms, formType]);

  //#endregion

  //#region Listeners

  function handleButtonNewClick(formType: string, formId: string) {
    if (moduleName === undefined) {
      return;
    }
    return navigate(redirectToFormView(location, moduleName, formType, formId));
  }

  // const onFarmChange = useCallback(
  //   (farm: IFarm) => {
  //     const redirectURL = redirectToListView(
  //       location,
  //       moduleName!,
  //       formType!,
  //       false
  //     );

  //     const newSearchParams = new URLSearchParams(
  //       window.location.search?.substring(1)
  //     );
  //     newSearchParams.set("farmId", farm.FarmCode);

  //     const _selectedHouseId = newSearchParams.get("houseId");
  //     if (!isNullEmptyOrWhitespace(_selectedHouseId)) {
  //       const _existsSelectedHouse = farm.Houses.some(
  //         (house) => house.HouseNumber.toString() === _selectedHouseId
  //       );
  //       if (!_existsSelectedHouse) {
  //         newSearchParams.delete("houseId");
  //       }
  //     }

  //     redirectURL.search = newSearchParams.toString();

  //     return navigate(redirectURL);
  //   },
  //   [formType, location, moduleName, navigate]
  // );

  // const onHouseChange = useCallback(
  //   (house: IFarmHouse) => {
  //     const searchParams = new URLSearchParams(
  //       window.location.search.substring(1)
  //     );
  //     searchParams.set("houseId", house.HouseNumber.toString());

  //     return navigate({
  //       search: "?" + searchParams.toString(),
  //     });
  //   },
  //   [navigate]
  // );

  // const onMenuChange = (menu: IMenuItem) => {
  //   if (menu.AppendQueryString) {
  //     const url = redirectWithExistingSearchParams(location, menu.Path);
  //     navigate(url);
  //   } else {
  //     navigate(menu.Path);
  //   }

  //   // TODO: trigger refresh of form values for formType in URL
  // };

  //#endregion

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb showHome={false} farmRequired={true} houseRequired={true} />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <div className="mb-2 flex flex-row items-center">
            <div className="text-lg text-gray-600 uppercase flex-grow font-medium">
              {pageTitle}
            </div>
            {newButtonOptions !== undefined && filteredForms.length > 0 ? (
              // <div className="absolute bottom-4 right-4">
              <ButtonNew
                className="justify-end"
                onClick={handleButtonNewClick}
                options={newButtonOptions}
              />
            ) : (
              // </div>
              <ButtonSkeleton />
            )}
          </div>
          {/* <div className="text-gray-600">
            Showing <MenuSelector onClickOption={onMenuChange} /> for{" "}
            <FarmSelector
              selectedFarmCode={farmId || undefined}
              onClickOption={onFarmChange}
            />{" "}
            {farmId !== null ? (
              <>
                in{" "}
                <HouseSelector
                  selectedFarmCode={farmId || undefined}
                  selectedHouseNumber={houseId || undefined}
                  onClickOption={onHouseChange}
                />
              </>
            ) : (
              ""
            )}
          </div> */}
        </div>

        <div className="col-span-full">
          <Card>
            {farmId !== null && houseId !== null && formData !== undefined ? (
              <div className="flex flex-col flex-grow space-y-4">
                <Historical
                  className="-mx-4"
                  farmId={farmId.toLowerCase()}
                  houseId={houseId.toString()}
                  forms={filteredForms.filter((f) =>
                    f.Permissions.includes("view")
                  )}
                  items={formData}
                />
              </div>
            ) : (
              <ListSkeleton />
            )}
          </Card>
        </div>

        <div className="col-span-full">
          <ActivePageDashboard />
        </div>
      </div>
    </main>
  );
}

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "..";
import classNames from "classnames";

export interface ModalProps {
  open: boolean;
  onOpen: (open: boolean) => void;
  children: React.ReactNode;
  title: string | React.ReactNode;
  onSave?: () => void;
  showCancel?: boolean;
  className?: string;
}

export function Modal({
  open = false,
  onOpen,
  children,
  title,
  onSave,
  showCancel = true,
  className,
  ...other
}: ModalProps) {
  function handleOpen(open: boolean) {
    onOpen(open);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleOpen}
      >
        <div className="flex items-start justify-center min-h-screen pt-56 px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="mt-4 inline-block align-bottom bg-white rounded-lg text-left shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className={classNames(className, "bg-white px-4 py-4 sm:p-6 sm:pb-4")}>
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                    {!!title && (
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900 mb-3"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    <div>{children}</div>
                  </div>
                </div>
              </div>
              {(showCancel || onSave) && (
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse inline-flex space-x-2">
                  {!!showCancel && (
                    <Button onClick={() => handleOpen(false)}>Cancel</Button>
                  )}
                  {!!onSave && (
                    <Button theme="primary" onClick={onSave}>
                      Save
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { XIcon } from "assets/icons/XIcon";
import { IListOption } from "helpers/formUtilities";
import React from "react";
import { Modal, Select, Toggle } from "./core";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string | React.ReactNode;
  children: React.ReactNode;
  settings: {
    id: string;
    label: string;
    type: "toggle" | "select";
    value: any;
    options?: IListOption[];
    setValue: (value: any) => void;
    checkedValue?: any;
    uncheckedValue?: any;
  }[];
}

export default function Settings({ open, setOpen, settings }: Props) {
  return (
    <Modal
      title="Settings"
      open={open}
      onOpen={(_open: boolean) => setOpen(_open)}
      showCancel={false}
    >
      <button
        type="button"
        className="absolute right-0 -top-8 text-gray-200 hover:text-primary focus:outline-none focus:ring-0"
        onClick={() => setOpen(false)}
      >
        <span className="sr-only">Close panel</span>
        <XIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="grid grid-cols-2">
        {settings.map((setting, index) => {
          if (setting.type === "toggle") {
            return (
              <div key={index} className="">
                <Toggle
                  id={setting.id}
                  label={setting.label}
                  value={setting.value}
                  checkedValue={setting.checkedValue}
                  uncheckedValue={setting.uncheckedValue}
                  setValue={(value: boolean) => setting.setValue(value)}
                />
              </div>
            );
          }

          if (setting.type === "select") {
            return (
              <div key={index} className="">
                <Select
                  id={setting.id}
                  label={setting.label}
                  value={setting.value}
                  setValue={(value: boolean) => setting.setValue(value)}
                  listOptions={setting.options}
                />
              </div>
            );
          }

          return null;
        })}
      </div>
    </Modal>
  );
}

import React from 'react'

interface SectionTitleProps {
  children: React.ReactNode
}

export default function SectionTitle({ children }: SectionTitleProps) {
  return (
    <div className="text-lg font-medium text-gray-600 flex flex-col uppercase">
      {children}
    </div>
  )
}
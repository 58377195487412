import {
  Page,
  Text,
  Document,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import { dateToString, localDate } from "helpers/dateUtilities";
import { IFarm } from "helpers/farmUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { ReactNode, useEffect, useMemo, useState } from "react";

interface IProps {
  farm: IFarm;
  auditForm: any;
  auditor: string;
  completedDate: Date;
  nonconformanceCounts: INonConformanceCount[];
  reportData: IReportData[];
  printImages: IReportImage[];
  printMode: ReportPrintMode;
  setIsPrinting: (isPrinting: boolean) => void;
}

interface IReportData {
  id: string;
  title: string;
  value: string | ReactNode;
  section: string;
  children: IReportDataChild[];
  hasNonConformance: boolean;
}

interface IReportDataChild {
  id: string;
  title: string;
  value: string | string[] | IImage[];
  type: "text" | "images" | "alert" | "status" | "hidden";
  statusColor?: "success" | "warning" | "danger" | "gray" | undefined;
  datasource: "audit" | "nonconformance";
}

interface IReportImage extends IReportDataChild {
  hasNonConformance: boolean;
}

interface IImage {
  id: string;
  url: string;
}

interface INonConformanceCount {
  id: string;
  text: string;
  severity: "secondary" | "success" | "warning" | "danger" | "gray" | undefined;
  value: string;
}

interface ITableData {
  headers: ITableHeader[];
  rows: ITableRow[];
}

interface ITableHeader {
  id: string;
  text: string;
}

interface ITableRow {
  id: string;
  cells: ITableCell[];
}

interface ITableCell {
  id: string;
  text: string | ReactNode;
}

export enum ReportPrintMode {
  AuditOnlyTable = 1,
  NonConformancesOnlyTable = 2,
  AuditOnlyTableWithLargeImages = 3,
  NonConformancesOnlyTableWithLargeImages = 4,
}

// Create Document Component
export const ReportPrintPDF = ({
  farm,
  auditForm,
  completedDate,
  auditor,
  nonconformanceCounts,
  reportData,
  printImages,
  printMode,
  setIsPrinting,
}: IProps) => {
  const filteredPrintImages = useMemo(() => {
    if (
      printMode === ReportPrintMode.NonConformancesOnlyTable ||
      printMode === ReportPrintMode.NonConformancesOnlyTableWithLargeImages
    ) {
      return printImages.filter((record) => {
        return record.hasNonConformance;
      });
    }
    return printImages;
  }, [printMode, printImages]);

  const tableData = useMemo(() => {
    // build table headers from reportData children where id is unique
    const tableHeaders = new Map();
    const tableRows: ITableRow[] = [];
    const tableData: ITableData = { headers: [], rows: [] };

    let filteredReportData = reportData;
    if (
      printMode === ReportPrintMode.NonConformancesOnlyTable ||
      printMode === ReportPrintMode.NonConformancesOnlyTableWithLargeImages
    ) {
      filteredReportData = reportData.filter((record) => {
        return record.hasNonConformance;
      });
    }

    tableHeaders.set("question", { id: "question", text: "Question" });
    for (const primaryQuestion of filteredReportData) {
      for (const childQuestion of primaryQuestion.children) {
        const auditPrintModes = [
          ReportPrintMode.AuditOnlyTable,
          ReportPrintMode.AuditOnlyTableWithLargeImages,
        ];
        const isAuditQuestion = childQuestion.datasource === "audit";

        if (
          childQuestion.id !== undefined &&
          !["alert", "hidden"].includes(childQuestion.type)  && // ncn alert message or hidden
          !tableHeaders.has(childQuestion.id) &&
          ((auditPrintModes.includes(printMode) && isAuditQuestion) || // audit only
            !auditPrintModes.includes(printMode)) // not audit
        ) {
          tableHeaders.set(childQuestion.id, {
            id: childQuestion.id,
            text: childQuestion.title,
            type: childQuestion.type,
          });
        }
      }
    }

    // build table rows from reportData children
    filteredReportData.forEach((record) => {
      const cells = [];
      cells.push({ id: "question", text: <Text>{record.title}</Text> });

      for (const tableHeader of tableHeaders.keys()) {
        if (tableHeader === "question") {
          continue;
        }
        const childData = record.children.find(
          (child) => child.id === tableHeader
        );

        if (childData === undefined) {
          cells.push({ id: tableHeader, text: <Text>-</Text> });
          continue;
        }

        if (childData.type === "hidden") {
          continue;
        }

        if (childData.type === "status") {
          const value = childData.value as string;
          cells.push({
            id: tableHeader,
            text: !isNullEmptyOrWhitespace(childData.value) ? (
              <Badge severity={childData.statusColor}>
                <Text>{value}</Text>
              </Badge>
            ) : (
              "-"
            ),
          });
          continue;
        }

        if (childData.type === "images") {
          cells.push({
            id: tableHeader,
            text: (
              <View style={{ display: "flex" }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    overflow: "hidden",
                  }}
                >
                  {(childData.value as string[]).map((imgUrl) => (
                    <View
                      style={{
                        // display: "flex",
                        height: "20px",
                        width: "20px",
                        borderRadius: "9999px",
                        backgroundColor: "rgb(244, 244, 245)",
                        // border: "2px solid #ffffff",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <Image
                        key={imgUrl}
                        style={{
                          objectFit: "cover",
                          height: "20px",
                          minWidth: "100%",
                          maxWidth: "100%",
                          marginHorizontal: "auto",
                        }}
                        src={{
                          uri: imgUrl,
                          method: "GET",
                          headers: { "Cache-Control": "no-cache" },
                          body: "",
                        }}
                      />
                    </View>
                  ))}
                </View>
              </View>
            ),
            type: childData.type,
          });
          continue;
        }

        cells.push({
          id: tableHeader,
          text: <Text>{childData.value as string | string[]}</Text>,
          type: childData.type,
        });
      }
      tableRows.push({ id: record.id, cells });
    });

    tableData.headers = Array.from(tableHeaders.values());
    tableData.rows = tableRows;

    return tableData;
  }, [printMode, reportData]);

  return (
    <Document>
      <Page style={styles.body} wrap>
        <View style={styles.header} fixed>
          <Text style={styles.headerItem}>
            <Text style={styles.headerLabel}>Farm:</Text>{" "}
            <Text>
              {farm?.FarmName}({farm?.FarmCode})
            </Text>
          </Text>
          <Text style={styles.headerItem}>
            <Text style={styles.headerLabel}>Audit:</Text>{" "}
            <Text>{auditForm?.FormTitle}</Text>
          </Text>
          <Text style={styles.headerItem}>
            <Text style={styles.headerLabel}>Audit date:</Text>{" "}
            <Text>
              {dateToString(completedDate, {
                includeTime: false,
              })}
            </Text>
          </Text>
        </View>
        <View style={styles.summary}>
          <Text style={styles.summaryLabel}>Summary:</Text>
          {nonconformanceCounts?.map((status) => (
            <Badge severity={status.severity}>
              <Text>
                {status.value} {status.text}
              </Text>
            </Badge>
          ))}
        </View>
        <Table data={tableData} />
        {(printMode === ReportPrintMode.AuditOnlyTableWithLargeImages ||
          printMode ===
            ReportPrintMode.NonConformancesOnlyTableWithLargeImages) &&
          filteredPrintImages.length > 0 && (
            <View>
              {filteredPrintImages.map((image, index) => (
                <View style={{ marginTop: "20px" }}>
                  <View style={styles.imageHeader}>
                    <Text>{image.title}</Text>
                  </View>
                  {(image.value as IImage[]).map((item, imgIndex) => (
                    <Image
                      key={item.url}
                      style={{
                        // height: "20px",
                        maxWidth: "100%",
                        marginHorizontal: "auto",
                      }}
                      src={{
                        uri: item.url,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                        body: "",
                      }}
                    />
                  ))}
                </View>
              ))}
            </View>
          )}
        <View style={styles.footer} fixed>
          <Text style={styles.footerItem}>
            <Text style={styles.footerLabel}>Auditor:</Text>{" "}
            <Text>{auditor}</Text>
          </Text>
          <Text
            render={({ pageNumber, totalPages }: { pageNumber: number, totalPages: number }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          />
          <Text style={styles.footerItem}>
            <Text style={styles.footerLabel}>Print date:</Text>{" "}
            <Text>
              {dateToString(localDate(), {
                includeTime: true,
              })}
            </Text>
          </Text>
          {/* <Text
            style={styles.footerItem}
            render={({ pageNumber, totalPages }) => (
              <Text style={styles.pageNumber}>
                Page {pageNumber} of ${totalPages}
              </Text>
            )}
          /> */}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 6,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
  },
  text: {
    margin: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: "500",
    height: "500",
  },
  imageHeader: {
    marginBottom: "2px",
    backgroundColor: "rgb(244, 244, 245)",
    borderBottom: "1px solid rgb(228, 228, 231)",
    paddingHorizontal: "2px",
    paddingVertical: "4px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    textAlign: "center",
  },
  headerItem: {},
  headerLabel: {
    color: "rgba(0,0,0,0.5)",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    textAlign: "left",
    position: "absolute",
    bottom: 30,
    left: 35,
    right: 35,
  },
  footerItem: {},
  footerLabel: {
    color: "rgba(0,0,0,0.5)",
  },
  summary: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  summaryLabel: {},
  pageNumber: {
    textAlign: "left",
  },
  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    borderTop: "1px solid rgb(244, 244, 245)",
  },
  tableHeader: {
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid rgb(228, 228, 231)",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    flex: 1,
    // @ts-ignore
    justifyContent: "stretch",
    textAlign: "left",
    borderRight: "1pt solid rgb(244, 244, 245)",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    paddingHorizontal: "2px",
    paddingVertical: "4px",
    pageBreakInside: "avoid !important",
  },
  tableRowCell: {
    flex: 1,
    // @ts-ignore
    justifyContent: "stretch",
    alignContent: "center",
    textAlign: "left",
    borderRight: "1pt solid rgb(244, 244, 245)",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    paddingHorizontal: "2px",
    paddingVertical: "4px",
    pageBreakInside: "avoid !important",
  },
  badge: {
    fontSize: 5,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "3px",
    paddingBottom: "3px",
    borderRadius: "9999px",
    lineHeight: 1,
  },
  badgeSecondary: {
    border: "1px solid rgb(208, 138, 208)",
    color: "rgb(175, 70, 175)",
    backgroundColor: "rgb(249, 240, 249)",
  },
  badgeDanger: {
    border: "1px solid rgb(248, 113, 113)",
    color: "rgb(220, 38, 38)",
    backgroundColor: "rgb(254, 242, 242)",
  },
  badgeWarning: {
    border: "1px solid rgb(251, 146, 60)",
    color: "rgb(234, 88, 12)",
    backgroundColor: "rgb(255, 247, 237)",
  },
  badgeSuccess: {
    border: "1px solid rgb(74, 222, 128)",
    color: "rgb(22, 163, 74)",
    backgroundColor: "rgb(240, 253, 244)",
  },
  badgeDefault: {
    border: "1px solid rgb(228, 228, 231)",
    color: "rgb(113, 113, 122)",
    backgroundColor: "rgb(244, 244, 245)",
  },
});

interface TableRowProps {
  items: any;
}

function getTableRowStyles(index: number) {
  let styles: any = {
    pageBreakInside: "avoid",
  };
  if (index === 0) {
    styles.borderTop = "none";
  }
  if (index % 2 === 0) {
    styles.backgroundColor = "rgb(250, 250, 250)";
  }

  return styles;
}

const TableRow = ({ items: rows }: TableRowProps) => {
  return rows.map((row: any, index: number) => (
    <View
      style={{ ...styles.tableRow, ...getTableRowStyles(index) }}
      key={row.id.toString()}
      wrap={false}
    >
      {row.cells.map((cell: any, cellIndex: number) => (
        <View style={styles.tableRowCell}>{cell.text}</View>
      ))}
    </View>
  ));
};

interface TableHeaderProps {
  items: { id: string | number; text: string | number }[];
}

const TableHeader = ({ items }: TableHeaderProps) => {
  return (
    <View style={styles.tableHeader} fixed>
      {items.map((item) => (
        <Text key={item.id} style={styles.tableHeaderCell}>
          {item.text}
        </Text>
      ))}
    </View>
  );
};

interface TableProps {
  data: any;
}

const Table = ({ data }: TableProps) => (
  <View style={styles.table}>
    <TableHeader items={data.headers} />
    <TableRow items={data.rows} />
    {/*<TableFooter items={data.items} />*/}
  </View>
);

interface BadgeProps {
  severity: string | undefined;
  children: React.ReactNode;
}

const Badge = ({ severity, children, ...other }: BadgeProps) => {
  const [theme, setTheme] = useState<string>("");

  useEffect(() => {
    switch (severity?.toString()) {
      case "4":
        setTheme("badgeSecondary");
        break;
      case "3":
        setTheme("badgeDanger");
        break;
      case "2":
        setTheme("badgeWarning");
        break;
      case "1":
        setTheme("badgeSuccess");
        break;
      default:
        setTheme("badgeDefault");
    }
  }, [severity]);

  return <View style={{ ...styles.badge, ...styles[theme] }}>{children}</View>;
};

import DynamicChart from "components/Charts/DynamicChart";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { AppDataContext, useMenus } from "context/AppDataProvider";
import {
  filterUserDashboardsByMenuId,
  formatChartData,
} from "helpers/dashboardUtilities";
import { dateToISO } from "helpers/dateUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useQuery from "hooks/useQuery";
import React, { useContext, useMemo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { IChartData } from "./types";
import Widget from "./Widget";

interface Props {
  className?: string;
}

export default function ActivePageDashboard({ className }: Props) {
  const { activeMenu } = useMenus();
  const query = useQuery();
  const { farms, dashboards, user } = useContext(AppDataContext);

  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const [chartsData, setChartsData] = useState<IChartData[] | undefined>(
    undefined
  );

  const dashboard = useMemo(() => {
    if (activeMenu?.ID === undefined) {
      return undefined;
    }

    const result = filterUserDashboardsByMenuId(
      dashboards,
      user,
      farms,
      activeMenu?.ID
    );

    return result?.length > 0 ? result[0] : undefined;
  }, [dashboards, farms, activeMenu?.ID, user]);

  /**
   * Fetch data & build charts
   */
  useDeepCompareEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    async function fetchData(dataSources: any) {
      if (isNullEmptyOrWhitespace(dataSources))
        throw Error("No data sources provided.");

      let params: { [key: string]: any } = {};

      const farm = farms.find(
        (farm) => farm.FarmCode.toLowerCase() === farmId?.toLowerCase()
      );
      if (farm !== undefined) {
        params.farmId = farm.FarmCode;
      }

      const house = farm?.Houses?.find(
        (h) => h.HouseNumber.toString() === houseId?.toString()
      );
      if (house) {
        params.houseId = house.HouseNumber;

        const cropDate = house?.Pens?.find(
          (p) => p.PenNumber.toString() === "1"
        )?.Placement?._CropDate.normalised;
        if (cropDate) {
          const cropDateFormatted = dateToISO(cropDate?.getTime());
          params.cropDate = cropDateFormatted;
        }
      }

      const paramKeys = Object.keys(params);
      const response = await fetch(
        `/api/chartdata-get?dataSource=${dataSources}${
          paramKeys.length
            ? "&" + paramKeys.map((key) => `${key}=${params[key]}`).join("&")
            : ""
        }`,
        {
          signal,
          method: "GET",
        }
      );

      if (!response.ok && signal.aborted === false) {
        throw Error(response.statusText);
      }

      const responseData = await response.json();

      return responseData;
    }

    (async function buildChartAndData() {
      if (dashboard === undefined) return;

      // Fetch dashboard data for each datasource
      const _dataSources: string[] = dashboard.dataSources.split(",");
      const responseArray = await Promise.all(
        _dataSources.map((dataSource) => fetchData(dataSource))
      );

      let _flattenedReports: any[] = [];
      let _flattenedStandards: any[] = [];
      for (const response of responseArray) {
        if (response?.d?.data) {
          _flattenedReports = _flattenedReports.concat(response.d.data);
        }
        if (response?.d?.standards) {
          _flattenedStandards = _flattenedStandards.concat(
            response.d.standards
          );
        }
      }

      // Sort by position, desc
      dashboard?.charts?.sort(
        (a: { position: number }, b: { position: number }) =>
          a.position - b.position
      );

      const _charts = dashboard?.charts ?? [];

      // Convert to metrics
      let newChartData: IChartData[] = [];
      for (const chart of _charts) {
        const _chartData = formatChartData(
          chart,
          _flattenedReports,
          _flattenedStandards
        );

        newChartData.push({
          id: chart.id,
          data: _chartData.data,
          standardData: _chartData.standardData,
        });
      }
      setChartsData(newChartData);
    })();

    return () => {
      abortController.abort();
      setChartsData(undefined);
    };
  }, [dashboard, farmId, farms, houseId]);

  if (dashboard === undefined) {
    return null;
  }

  if (dashboard.charts && chartsData === undefined) {
    // loading chart data
    return <ListSkeleton />;
  }

  return (
    <div className="grid gap-4 grid-cols-12">
      {dashboard.charts.map((chart: any) => {
        const chartData = chartsData?.find((cd) => cd.id === chart.id);

        return (
          <Widget
            key={chart.id}
            title={chart.title}
            className={"relative"}
            showTitle={chart.showHeader}
            size={chart?.settings?.widgetSize}
          >
            <DynamicChart
              key={chart.id}
              chart={chart}
              data={chartData?.data}
              standardData={chartData?.standardData}
            />
          </Widget>
        );
      })}
    </div>
  );
}

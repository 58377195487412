import {
  TrashIcon,
  CogIcon,
  CursorClickIcon,
  AnnotationIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/outline";
import React from "react";
import { Button, FlyoutMenu, Input, Select } from "components/core";
import DynamicChart from "components/Charts/DynamicChart";
import DashboardWidget from "../Widget";
import { CHART_TYPE } from "constants.js";
import { Toggle } from "components/core/Forms/Toggle";
import AddMetricButton from "./AddMetricButton";
import AddColorButton from "./AddColorButton";
import classNames from "classnames";

const Widget = ({
  chart,
  data,
  standardData,
  onDelete,
  onChangeTitle,
  onChangeSetting,
  onChangeAttr,
  metrics,
  standardMetrics,
  onAddMetrics,
  onAddTooltipMetrics,
  onAddStandardMetrics,
  onAddColors,
}) => {
  function handleChangeSettings(name, value) {
    onChangeSetting(chart.id, name, value);
  }

  function handleChangeAttr(name, value) {
    onChangeAttr(chart.id, name, value);
  }

  function getWidgetSizeClass() {
    switch (chart?.settings?.widgetSize) {
      case "small":
        return "col-span-12 laptop:col-span-3";
      case "medium":
        return "col-span-12 laptop:col-span-4";
      case "large":
        return "col-span-12 laptop:col-span-6";
      case "xlarge":
        return "col-span-12 laptop:col-span-9";
      default:
        return "col-span-12";
    }
  }

  const _showTitle = chart?.settings?.showTitle ?? true;

  return (
    <>
      <DashboardWidget
        key={chart.id}
        title={
          <input
            className=""
            onBlur={(ev) => onChangeTitle(chart.id, ev.target.value)}
            defaultValue={chart.title}
          />
        }
        showTitle={_showTitle}
        actions={
          <div className="inline-flex space-x-1">
            <div className="dragHandle py-1 px-2 border border-gray-300 rounded-sm shadow-sm cursor-move">
              <CursorClickIcon className="w-4 h-4 text-gray-400" />
            </div>
            <AddMetricButton
              chartType={chart.type}
              onAddMetrics={(metricIds) => onAddMetrics(chart.id, metricIds)}
              className="col-span-12"
              metrics={metrics}
              selectedMetrics={chart.keys?.map((key) => key.id)?.join(",")} // At the moment chart.keys is an array of metrics, if this format changes we may need to do a lookup against `metrics` property
            />
            {[CHART_TYPE.SCATTER, CHART_TYPE.LINE, CHART_TYPE.BAR].includes(chart.type) ? (
              <AddMetricButton
                title="Add Tooltip Metrics"
                chartType={chart.type}
                onAddMetrics={(metricIds) =>
                  onAddTooltipMetrics(chart.id, metricIds)
                }
                className="col-span-12"
                metrics={metrics}
                selectedMetrics={chart.tooltipKeys
                  ?.map((key) => key.id)
                  ?.join(",")} // At the moment chart.keys is an array of metrics, if this format changes we may need to do a lookup against `metrics` property
                buttonIcon={AnnotationIcon}
                buttonTitle="Add Tooltip Metrics"
              />
            ) : null}
            {standardMetrics?.length > 0 && [CHART_TYPE.SCATTER, CHART_TYPE.LINE].includes(chart.type) ? (
              <AddMetricButton
                title="Add Standard Metrics"
                chartType={chart.type}
                onAddMetrics={(metricIds) =>
                  onAddStandardMetrics(chart.id, metricIds)
                }
                className="col-span-12"
                metrics={standardMetrics}
                selectedMetrics={chart.standardKeys
                  ?.map((key) => key.id)
                  ?.join(",")} // At the moment chart.keys is an array of metrics, if this format changes we may need to do a lookup against `metrics` property
                buttonIcon={PresentationChartLineIcon}
                buttonTitle="Add Standard Metrics"
              />
            ) : null}
            {[CHART_TYPE.BAR, CHART_TYPE.LINE, CHART_TYPE.SCATTER].includes(chart.type) && (
              <AddColorButton
                chartType={chart.type}
                onAddColors={(color) => onAddColors(chart.id, color)}
                className="col-span-12"
                selectedColors={chart?.settings?.colors} // At the moment chart.keys is an array of metrics, if this format changes we may need to do a lookup against `metrics` property
              />
            )}
            <Button
              size="small"
              type="button"
              onClick={() => onDelete(chart.id)}
            >
              <TrashIcon className="w-4 h-4 text-gray-400" />
            </Button>
            <FlyoutMenu
              buttonClassNames="inline-flex items-center justify-start border-0 text-sm uppercase py-1 px-2 text-xs rounded-sm shadow-sm border border-gray-300 shadow-sm font-medium text-gray-700 hover:bg-gray-50 focus:border-primary focus:text-primary focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10"
              buttonContent={() => (
                <CogIcon className="w-4 h-4 text-gray-400" />
              )}
            >
              <div>
                <div className="text-sm font-bold mb-4">Settings</div>
                <div className="divide-y">
                  <div className="py-4">
                    <ToggleSetting
                      id="show-title-toggle"
                      label="Show title"
                      labelPosition="inset"
                      value={_showTitle}
                      onChange={(value) =>
                        handleChangeSettings("showTitle", value)
                      }
                    />
                  </div>
                  <div className="py-4">
                    <ToggleSetting
                      id="show-config-toggle"
                      label="Show config"
                      labelPosition="inset"
                      value={chart?.settings?.showConfig ?? true}
                      onChange={(value) =>
                        handleChangeSettings("showConfig", value)
                      }
                    />
                  </div>
                  {[CHART_TYPE.LINE, CHART_TYPE.BAR].includes(chart.type) && (
                    <div className="py-4 space-y-2">
                      <div className="font-medium text-sm">Axis label:</div>
                      <div className="flex items-center space-x-2">
                        <ToggleSetting
                          id="show-left-axis-label"
                          label="Show left"
                          labelPosition="inset"
                          value={chart?.settings?.showLeftAxisLabel}
                          onChange={(value) =>
                            handleChangeSettings("showLeftAxisLabel", value)
                          }
                        />
                        <ToggleSetting
                          id="show-bottom-axis-label"
                          label="Show bottom"
                          labelPosition="inset"
                          value={chart?.settings?.showBottomAxisLabel}
                          onChange={(value) =>
                            handleChangeSettings("showBottomAxisLabel", value)
                          }
                        />
                      </div>
                    </div>
                  )}
                  {chart.type === CHART_TYPE.LINE && (
                    <div className="py-4">
                      <SelectSetting
                        id="widget-curve-type"
                        label="Curve type"
                        value={chart?.attrs?.curveType ?? "catmullRom"}
                        listOptions={[
                          {
                            Id: "catmullRom",
                            Text: "catmullRom",
                            Value: "catmullRom",
                          },
                          {
                            Id: "linear",
                            Text: "Linear",
                            Value: "linear",
                          },
                          {
                            Id: "basis",
                            Text: "Basis",
                            Value: "basis",
                          },
                          {
                            Id: "step",
                            Text: "Step",
                            Value: "step",
                          },
                        ]}
                        onChange={(value) =>
                          handleChangeAttr("curveType", value)
                        }
                      />
                    </div>
                  )}
                  {chart.type === CHART_TYPE.TREND && (
                    <div className="py-4">
                      <ToggleSetting
                        id="inverted-toggle"
                        label="Inverted"
                        value={chart?.settings?.inverted}
                        onChange={(value) =>
                          handleChangeSettings("inverted", value)
                        }
                      />
                    </div>
                  )}
                  {chart.type === CHART_TYPE.BAR && (
                    <div className="py-4 space-y-2">
                      <div className="font-medium text-sm">Margin offset:</div>
                      <div className="flex items-center space-x-2">
                        <InputSetting
                          id="margin-top-offset-input"
                          label="Top"
                          value={chart?.settings?.marginTopOffset}
                          onChange={(value) => {
                            handleChangeSettings("marginTopOffset", value);
                          }}
                        />
                        <InputSetting
                          id="margin-right-offset-input"
                          label="Right"
                          value={chart?.settings?.marginRightOffset}
                          onChange={(value) => {
                            handleChangeSettings("marginRightOffset", value);
                          }}
                        />
                        <InputSetting
                          id="margin-bottom-offset-input"
                          label="Bottom"
                          type="number"
                          value={chart?.settings?.marginBottomOffset}
                          onChange={(value) => {
                            handleChangeSettings("marginBottomOffset", value);
                          }}
                        />
                        <InputSetting
                          id="margin-left-offset-input"
                          label="Left"
                          value={chart?.settings?.marginLeftOffset}
                          onChange={(value) => {
                            handleChangeSettings("marginLeftOffset", value);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="py-4">
                    <SelectSetting
                      id="widget-size-trend-select"
                      label="Widget size"
                      value={chart?.settings?.widgetSize}
                      listOptions={[
                        {
                          Id: "small",
                          Text: "25%",
                          Value: "small",
                        },
                        {
                          Id: "medium",
                          Text: "33%",
                          Value: "medium",
                        },
                        {
                          Id: "large",
                          Text: "50%",
                          Value: "large",
                        },
                        {
                          Id: "xlarge",
                          Text: "75%",
                          Value: "xlarge",
                        },
                        {
                          Id: "xxlarge",
                          Text: "100%",
                          Value: "xxlarge",
                        },
                      ]}
                      onChange={(value) =>
                        handleChangeSettings("widgetSize", value)
                      }
                    />
                  </div>
                </div>
              </div>
            </FlyoutMenu>
          </div>
        }
        className={classNames("relative", getWidgetSizeClass())}
      >
        <DynamicChart
          key={chart.id}
          chart={chart}
          data={data}
          standardData={standardData}
          settings={chart.settings}
        />
      </DashboardWidget>
    </>
  );
};

function ToggleSetting(props) {
  return (
    <div className="inline-flex">
      <Toggle
        id={props.id}
        label={props.label}
        value={props.value}
        setValue={props.onChange}
        checkedValue={true}
        uncheckedValue={false}
        labelPosition={props.labelPosition}
      />
    </div>
  );
}

function SelectSetting(props) {
  return (
    <Select
      id={props.id}
      label={props.label}
      value={props.value}
      labelPosition="inset"
      listOptions={props.listOptions}
      setValue={props.onChange}
    />
  );
}

function InputSetting(props) {
  return (
    <div className="inline-flex">
      <Input
        id={props.id}
        label={props.label}
        value={props.value}
        setValue={props.onChange}
        labelPosition="inset"
        type={props.type}
      />
    </div>
  );
}

export default Widget;

import Dexie from "dexie";

export default class LocalDB {
  constructor(dbName) {
    this.db = new Dexie(dbName);
    this.db.version(0.1).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,FormName,HouseNumber,DateApplies,LastModified",
    });

    this.db.version(0.2).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,FormType,FormName,HouseNumber,DateApplies,LastModified",
    });

    this.db.version(0.3).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,MenuOption,FormName,HouseNumber,DateApplies,LastModified",
    });

    this.db.version(0.4).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,FormType,FormName,HouseNumber,DateApplies,LastModified",
    });

    // // Remove listItems
    // this.db.version(0.2).stores({
    //   listItems: null,
    // });

    // // Add ID to productionEntries
    // this.db.version(0.3).stores({
    //   productionEntries: null,
    //   formdata:
    //     "[FarmCode+FormName+HouseNumber+DateApplies+ID],timestamp",
    // });

    return this.db;
  }

  deleteDB() {
    return this.db.delete();
  }
}

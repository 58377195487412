import { ColorSwatchIcon } from "assets/icons";
import classNames from "classnames";
import { Button, Input, Modal } from "components/core";
import { useState } from "react";

const numOfColorFields = 5;

export default function AddColorButton({
  chartType,
  onAddColors,
  className,
  selectedColors: selectedColorsProp,
  ...other
}) {
  const [open, setOpen] = useState(false);
  const [selectedColors, setSelectedColors] = useState(
    selectedColorsProp ?? []
  );

  function handleClickAddColors() {
    setOpen(true);
  }

  function handleSaveColors() {
    onAddColors(selectedColors);
    setOpen(false);
  }

  function handleSelectColor(color, index) {
    const newSelectedColors = [...selectedColors];
    newSelectedColors[index] = color;
    setSelectedColors(newSelectedColors);
  }

  return (
    <>
      <Button
        className={classNames(className)}
        size="small"
        type="button"
        onClick={handleClickAddColors}
      >
        <ColorSwatchIcon className="w-4 h-4 text-gray-400" />
      </Button>
      <Modal
        title="Add colors"
        open={open}
        onOpen={(_open) => setOpen(_open)}
        onSave={() => handleSaveColors()}
      >
        {Array.from(Array(numOfColorFields)).map((_, index) => (
          <div key={`color_${index}`} className="py-2">
            <Input
              id="colors"
              type="color"
              label={`Color ${index + 1}`}
              labelPosition="inset"
              value={selectedColors[index]}
              setValue={(value) => handleSelectColor(value, index)}
              disableCalcTrigger={true}
            />
          </div>
        ))}
        <p className="text-sm">Not happy? <span className="text-primary cursor-pointer" onClick={() => setSelectedColors(["#df386f", "#01295F", "#437F97", "#849324", "#FFB30F"])}>reset to default</span></p>
      </Modal>
    </>
  );
}

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Label = ({
  id,
  text,
  required,
  focused,
  hasValue,
  position,
  addonPosition,
  theme,
  size = "base",
  erroneous,
  labelClasses,
  className = "",
  ...other
}) => {
  return (
    <div
      className={classNames(
        className,
        position === "inset"
          ? "flex px-3 absolute z-10 pointer-events-none transition-transform transition-colors duration-200 inset-x-0 -translate-y-2 text-xs"
          : "flex relative items-center",
        position !== "left" ? "justify-between" : "",
        position === "inset" && focused
          ? theme === "darkgray"
            ? "text-gray-400 dark:text-gray-500"
            : "text-gray-800 dark:text-gray-200"
          : "text-gray-500 dark:text-gray-300",
        erroneous ? "text-danger-500" : ""
      )}
      data-cy="label"
    >
      {text && (
        <label
          htmlFor={id}
          className={classNames(
            position === "inset"
              ? "px-1 whitespace-nowrap"
              : "block text-gray-700 dark:text-gray-300 mr-1 font-medium",
            position === "inset"
              ? theme === "darkgray"
                ? "bg-gray-900"
                : "bg-white"
              : "",
            position === "inset"
              ? "text-xs"
              : size === "small"
              ? "text-xs"
              : size === "large"
              ? "text-lg"
              : "text-sm",
            labelClasses
          )}
          {...other}
        >
          {text}
        </label>
      )}
      {required && (
        <span
          className={classNames(
            !erroneous && "text-primary text-sm",
            position === "inset" ? "pr-1 text-xs" : "",
            position === "inset" && theme === "darkgray"
              ? "bg-gray-900"
              : "bg-white"
          )}
        >
          *
        </span>
      )}
    </div>
  );
};

Label.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  required: PropTypes.bool,
  focused: PropTypes.bool,
  position: PropTypes.oneOf(["top", "left", "inset"]),
  size: PropTypes.oneOf(["large", "small", undefined]),
  addonPosition: PropTypes.oneOf(["left", "right"]),
};

export { Label };

import { useState, useEffect } from "react";
import { createUltimatePagination as pagination } from "react-ultimate-pagination";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "assets/icons";
import classNames from "classnames";
// var RUPagination = require('react-ultimate-pagination');

const Pagination = (props) => {
  const [currentPage, setCurrentPage] = useState(props.currentPage ?? 1);

  /**
   * Watch for changes to currentPage prop
   */
  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);
  

  function onChangePage(page) {
    setCurrentPage(page);
    props.onChangePage(page);
  }

  function Page(props) {
    return (
      <button
        className={classNames(
          props.isActive
            ? "z-10 bg-primary-lightest border-primary-light text-primary"
            : "bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hidden laptop:inline-flex",
          "relative items-center px-4 py-2 border text-sm font-medium"
        )}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.value}
      </button>
    );
  }

  function Ellipsis(props) {
    return (
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        className="relative items-center px-4 py-2 border border-gray-200 bg-white text-sm font-medium text-gray-700 hidden laptop:inline-flex"
      >
        ...
      </button>
    );
  }

  function FirstPageLink(props) {
    return (
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-200 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <ChevronDoubleLeftIcon className="w-4 h-4" />
      </button>
    );
  }

  function PreviousPageLink(props) {
    return (
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        className="relative inline-flex items-center px-2 py-2 border border-gray-200 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <ChevronLeftIcon className="w-4 h-4" />
      </button>
    );
  }

  function NextPageLink(props) {
    return (
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        className="relative inline-flex items-center px-2 py-2 border border-gray-200 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
      >
        <ChevronRightIcon className="w-4 h-4" />
      </button>
    );
  }

  function LastPageLink(props) {
    return (
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        className="ml-3 relative inline-flex items-center px-2 py-2 border border-gray-200 text-sm font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50"
      >
        <ChevronDoubleRightIcon className="w-4 h-4" />
      </button>
    );
  }

  function Wrapper(props) {
    return (
      <div className="flex-1 flex justify-center laptop:justify-between sm:hidden items-center">
        <div className="hidden laptop:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{props.recordCount}</span> results over <span className="font-medium">{props.totalPages}</span> pages
          </p>
        </div>
        <nav
          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          {props.children}
        </nav>
      </div>
    );
  }

  const CustomPagination = pagination({
    itemTypeToComponent: {
      PAGE: Page,
      ELLIPSIS: Ellipsis,
      FIRST_PAGE_LINK: FirstPageLink,
      PREVIOUS_PAGE_LINK: PreviousPageLink,
      NEXT_PAGE_LINK: NextPageLink,
      LAST_PAGE_LINK: LastPageLink,
    },
    WrapperComponent: (wrapperProps) => Wrapper({...wrapperProps, ...props}),
  });

  return (
    <CustomPagination
      totalPages={props.totalPages}
      currentPage={currentPage}
      onChange={(page) => onChangePage(page)}
    />
  );
};

export { Pagination };
